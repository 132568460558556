import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Alert, Form, Modal } from 'react-bootstrap';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { Icon } from '@iconify/react';
import './Cancel.css';

// URL-safe base64 encoding and decoding functions
const encodeProductId = (productId) => {
    return btoa(productId).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

const decodeProductId = (encodedProductId) => {
    encodedProductId = encodedProductId.replace(/-/g, '+').replace(/_/g, '/');
    // No need to add padding characters back since we'll decode directly
    return atob(encodedProductId);
};

const CancelSubscription = () => {
    const navigate = useNavigate();
    const auth = getAuth();
    const db = getFirestore();
    const [user, setUser] = useState(null);
    const [bundle, setBundle] = useState(null);
    const [bundleScore, setBundleScore] = useState(0);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [uid, setUid] = useState(null);
    const [cancelReason, setCancelReason] = useState('');
    const [reasonError, setReasonError] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productModalOpen, setProductModalOpen] = useState(false);

    const fetchUserData = async (uid) => {
        try {
            const userDoc = await getDoc(doc(db, "customers", uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setUser(userData);
                const bundleName = userData?.bundle?.bundleName;
                if (bundleName) {
                    const bundleQuery = query(collection(db, "bundles"), where("name", "==", bundleName));
                    const bundleSnapshot = await getDocs(bundleQuery);
                    if (!bundleSnapshot.empty) {
                        const bundleData = bundleSnapshot.docs[0].data();
                        setBundle(bundleData);
                        setBundleScore(bundleData.score || 0);
                    }
                }
                const productKeys = Object.keys(userData?.products || {});
                const productPromises = productKeys.map(key => getDoc(doc(db, "products", decodeProductId(key))));
                const productDocs = await Promise.all(productPromises);
                const fetchedProducts = productDocs.map(doc => ({ id: encodeProductId(doc.id), ...doc.data() }));
                setProducts(fetchedProducts);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
        setLoading(false);
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUid(user.uid);
                fetchUserData(user.uid);
            } else {
                setLoading(false);
            }
        });
        return () => unsubscribe();
    }, [auth]);

    const handleCancel = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        if (!cancelReason) {
            setReasonError('Cancellation reason is required');
            setLoading(false);
            return;
        }

        try {
            const pastChargeDates = user.bundle.pastChargeDates || [];
            const chargeCount = pastChargeDates.length;
            const frequency = user.bundle.frequency;

            let cancelUrl = 'https://us-central1-shopdot-b9a58.cloudfunctions.net/cancelSubscriptionDelay';
            let cancelType = 'end of billing cycle';

            if (chargeCount === 0 || frequency === 'annual' ||
                (frequency === 'bi-monthly' && chargeCount % 2 === 0) ||
                (frequency === 'quarterly' && chargeCount % 3 === 0)) {
                cancelUrl = 'https://us-central1-shopdot-b9a58.cloudfunctions.net/cancelSubscriptionImmediate';
                cancelType = 'immediate';
            }

            const response = await fetch(cancelUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ subscriptionId: user.bundle.subscriptionId }),
            });

            const data = await response.json();
            if (response.ok) {
                const customerRef = doc(db, 'customers', uid);
                await updateDoc(customerRef, {
                    'bundle.canceled': true,
                    'bundle.cancelDate': new Date().toISOString(),
                    'bundle.cancelReason': cancelReason,
                    'bundle.cancelType': cancelType
                });

                setSuccess(data.message);
                setTimeout(() => {
                    navigate('/');
                }, 3000);
            } else {
                setError(data.error || 'Failed to cancel subscription');
            }
        } catch (err) {
            setError('Failed to cancel subscription');
        } finally {
            setLoading(false);
        }
    };

    const handleCancelProduct = async () => {
        if (!selectedProduct) {
            setError('No product selected');
            return;
        }

        setLoading(true);
        setError('');
        setSuccess('');

        try {
            const pastChargeDates = user.products[selectedProduct.id].pastChargeDates || [];
            const chargeCount = pastChargeDates.length;
            const frequency = user.products[selectedProduct.id].frequency;

            let cancelUrl = 'https://us-central1-shopdot-b9a58.cloudfunctions.net/cancelSubscriptionDelay';
            let cancelType = 'end of billing cycle';

            if (chargeCount === 0 || frequency === 'annual' ||
                (frequency === 'bi-monthly' && chargeCount % 2 === 0) ||
                (frequency === 'quarterly' && chargeCount % 3 === 0)) {
                cancelUrl = 'https://us-central1-shopdot-b9a58.cloudfunctions.net/cancelSubscriptionImmediate';
                cancelType = 'immediate';
            }

            const response = await fetch(cancelUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ subscriptionId: user.products[selectedProduct.id].subscriptionId }),
            });

            const data = await response.json();
            if (response.ok) {
                const customerRef = doc(db, 'customers', uid);
                const updatedProducts = { ...user.products, [selectedProduct.id]: { ...user.products[selectedProduct.id], canceled: true } };

                await updateDoc(customerRef, {
                    products: updatedProducts
                });

                setSuccess('Product canceled successfully');
                setProductModalOpen(false);
                setTimeout(() => {
                    fetchUserData(uid);
                }, 3000);
            } else {
                setError(data.error || 'Failed to cancel product');
            }
        } catch (err) {
            setError('Failed to cancel product');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    const alreadyCanceled = user?.bundle?.canceled;
    const pastChargeDates = user?.bundle?.pastChargeDates || [];
    const chargeCount = pastChargeDates.length;
    const frequency = user?.bundle?.frequency;
    const nextChargeDate = user?.bundle?.nextChargeDate ? user.bundle.nextChargeDate.toDate() : null;
    const formattedNextChargeDate = nextChargeDate ? nextChargeDate.toLocaleDateString() : null;

    return (
        <div className="cancel-main">
            <div className="profile-head" style={{ marginBottom: '0px' }}>
                <Link to="#" onClick={() => navigate(-1)}><Icon icon="ph:arrow-left" /></Link>
            </div>
            <div className="bundle-for-you" style={{ marginTop: '12px', marginLeft: '8px', padding: '0px' }}>
                <h3>Cancel Subscription</h3>
            </div>
            {bundle ? (
                <div className="bundle-card-cancel" style={{ }}>
                    <div className="score-card-wrap score-card-bg" style={{ border: "1px solid #EEE", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                            <div className="image-grid-bundle">
                                <img src={bundle?.sunscreen?.colored_photo} alt="Product 1" style={{ borderTopLeftRadius: '8px' }} />
                                <img src={bundle?.face_wash?.colored_photo} alt="Product 2" style={{ borderTopRightRadius: '8px' }} />
                                <img src={bundle?.moisturizer?.colored_photo} alt="Product 3" style={{ borderBottomLeftRadius: '8px' }} />
                                <img src={bundle?.moisturizer?.plain_photo_1} alt="Product 4" style={{ borderBottomRightRadius: '8px' }} />
                            </div>
                            <div className="score-card-info">
                                <h5 className="truncate-text">{bundle?.name}</h5>
                                <p>{bundle?.face_wash?.brand}, {bundle?.moisturizer?.brand}, {bundle?.sunscreen?.brand} <span></span></p>
                                {frequency !== 'annual' && <h6>${user?.bundle?.bundlePrice?.toFixed(2)}/month</h6>}
                                {frequency === 'annual' && <h6>${user?.bundle?.bundlePrice?.toFixed(2)}/year</h6>}
                            </div>
                            <div className="score-card-score-general bg-eeee">
                                <h4>{Math.round(bundle?.dotscore)} <span><Icon icon="mdi:chevron-up-circle-outline" /></span></h4>
                                <i>dotScore</i>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>No bundle data available</div>
            )}

            {/* Product cards */}
            {products.map((product, index) => (
                <div key={index} className="score-card-wrap mb-2.5 score-card-bg" style={{ border: "1px solid #EEE", backgroundColor: user?.products[product.id]?.canceled ? '#FF6961' : 'white' }}>
                    <div className="score-card-img">
                        <img src={product.plain_photo_1} alt={product.name} />
                    </div>
                    <div className="score-card-info">
                        <h5 className="truncate-text">{product.name}</h5>
                        <p>{product.brand} <span>{/* #tag here */}</span></p>
                        <h6>{user?.products[product.id]?.canceled ? 'Canceled' : `$${product.price?.toFixed(2)}`}</h6>
                    </div>
                    <div className="score-card-score-general bg-white">
                        <h4>{product.dotscore.toFixed()} <span><Icon icon="mdi:chevron-up-circle-outline" /></span></h4>
                        <i>dotScore</i>
                    </div>
                    {!user?.products[product.id]?.canceled && (
                        <Button variant="danger" style={{backgroundColor: '#FF6961'}} onClick={() => {
                            setSelectedProduct(product);
                            setProductModalOpen(true);
                        }}>
                            Cancel Product
                        </Button>
                    )}
                </div>
            ))}

            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            {alreadyCanceled && <Alert variant="warning">Your subscription has already been canceled</Alert>}
            {!alreadyCanceled && (
                <div className="manage-routine-small-text" style={{fontSize: '14px'}}>
                    {chargeCount === 0 || frequency === 'annual' ||
                    (frequency === 'bi-monthly' && chargeCount % 2 === 0) ||
                    (frequency === 'quarterly' && chargeCount % 3 === 0) ? (
                        <h2>Your subscription will be canceled <b>immediately</b> and you won't be charged any further.</h2>
                    ) : (
                        <h2>Your last charge will be at the <b>end of your current subscription period </b> on {formattedNextChargeDate}</h2>
                    )}
                </div>
            )}
            <Form.Group controlId="cancelReason">
                <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Please provide a reason for your cancellation"
                    value={cancelReason}
                    onChange={(e) => {
                        setCancelReason(e.target.value);
                        setReasonError('');
                    }}
                    isInvalid={!!reasonError}
                />
                <Form.Control.Feedback type="invalid">
                    {reasonError}
                </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" onClick={handleCancel} className="long-black-right" style={{backgroundColor: 'black', border: '1px black', marginTop: '16px'}} disabled={loading}>
                {loading ? 'Processing...' : 'Cancel Subscription'}
            </Button>

            <Modal show={productModalOpen} onHide={() => setProductModalOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to cancel this product?</p>
                    <Button variant="danger" style={{backgroundColor: '#FF6961'}} onClick={handleCancelProduct}>
                        Cancel Product
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CancelSubscription;
