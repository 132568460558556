import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Container, Modal, Image, Accordion } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import SignIn from "../../Pages/SignIn/SignIn";
import { Icon } from "@iconify/react";
import './Header.css';
import learnvidexample from '../../images/LearnVideoExample.png'


function Header() {
    const [show, setShow] = useState(false);
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [activeKey, setActiveKey] = useState(0)
    const [showLearn, setShowLearn] = useState(false)

    const location = useLocation();
    const auth = getAuth();

    const handleClose = (val) => {
        setShow(false)
    };
    const handleShow = () => setShow(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in
                setIsUserSignedIn(true);
            } else {
                // User is signed out
                setIsUserSignedIn(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [auth]);

    const handleAccordionClick = (key) => {
        setActiveKey(key);
    };    

    return (
        <header className="header-wrap">
            <Container className={`${location.pathname !== '/' ? 'bg-white' : ''}`}>
                <Link to="/" className="brand-logo">
                    <Image src="/images/logo.svg" />
                </Link>
                <div className="nav-items">
                    {/* <Link to="#" className="btn-gray btn-cart">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.875 5H13.75C13.75 4.00544 13.3549 3.05161 12.6517 2.34835C11.9484 1.64509 10.9946 1.25 10 1.25C9.00544 1.25 8.05161 1.64509 7.34835 2.34835C6.64509 3.05161 6.25 4.00544 6.25 5H3.125C2.79348 5 2.47554 5.1317 2.24112 5.36612C2.0067 5.60054 1.875 5.91848 1.875 6.25V15.625C1.875 15.9565 2.0067 16.2745 2.24112 16.5089C2.47554 16.7433 2.79348 16.875 3.125 16.875H16.875C17.2065 16.875 17.5245 16.7433 17.7589 16.5089C17.9933 16.2745 18.125 15.9565 18.125 15.625V6.25C18.125 5.91848 17.9933 5.60054 17.7589 5.36612C17.5245 5.1317 17.2065 5 16.875 5ZM10 2.5C10.663 2.5 11.2989 2.76339 11.7678 3.23223C12.2366 3.70107 12.5 4.33696 12.5 5H7.5C7.5 4.33696 7.76339 3.70107 8.23223 3.23223C8.70107 2.76339 9.33696 2.5 10 2.5ZM16.875 15.625H3.125V6.25H6.25V7.5C6.25 7.66576 6.31585 7.82473 6.43306 7.94194C6.55027 8.05915 6.70924 8.125 6.875 8.125C7.04076 8.125 7.19973 8.05915 7.31694 7.94194C7.43415 7.82473 7.5 7.66576 7.5 7.5V6.25H12.5V7.5C12.5 7.66576 12.5658 7.82473 12.6831 7.94194C12.8003 8.05915 12.9592 8.125 13.125 8.125C13.2908 8.125 13.4497 8.05915 13.5669 7.94194C13.6842 7.82473 13.75 7.66576 13.75 7.5V6.25H16.875V15.625Z" fill="#1F1F1F" />
                        </svg>
                    </Link> */}
                    <div className="nav-items">
                        <div className="btn btn-1 btn-default underline" onClick={() => {setShowLearn(true)}}>Learn</div>
                    </div>

                    <div className="nav-items">
                        {(
                            isUserSignedIn
                                ? <Link to="/Profile" className="btn btn-gray btn-default">My Account</Link>
                                : <Link className="btn btn-dark btn-default text-white text-xl" onClick={handleShow}><Icon icon={"octicon:person-24"} /></Link>
                        )}
                    </div>

                    {/* <div className="nav-items">
                        <Link to="/ShoppingCart" className="btn btn-gray btn-default" style={{marginLeft:'4px'}}>
                            <Icon icon="mi:shopping-cart" style={{fontSize: '20px'}}/>
                        </Link>
                    </div> */}

                    <Modal show={showLearn} className="modal-dialog-scrollable" style={{marginTop: "-30px"}}>
                        <Modal.Body>
                            <span onClick={() => setShowLearn(false)}><Icon icon={"material-symbols:close"}/></span>

                            <div className="flex flex-col space-y-5 px-2 mt-2">
                               
                                <span className="font-bold">a brief intro</span>

                                <h2 className="text-3xl font-bold">
                                    <span className="bg-[#A5E1BF] p-1 rounded">shopdot</span> helps you find what's right for <span className="text-[#C69575]">your</span> skin.
                                </h2>

                                {/* <img src={learnvidexample}/> */}
                                

                                <p>
                                Hello! We're shopdot. We compare sunscreens & skincare products using <b>real skincare expertise.</b>
                                </p>

                                <p>
                                    <b>Our goal: to help you shop smart.</b> That means knowing exactly how your purchase could affect your skin before you hit 'buy'.
                                </p>

                                <div className="rounded-xl flex flex-col space-y-3 p-3 shadow-lg">
                                    
                                    <h3 className="text-xl font-bold">Learn More</h3>

                                    <span>Share your questions or tell us what you think <Link to="mailto:hunter@theshopdot.com"className="font-bold underline">here!</Link></span>

                                    <Accordion  className="Review-Accordion-Desktop space-y-6">

                                    <LearnAccordion
                                        itemKey="0" // Ensure the key is a string
                                        activeKey={activeKey.toString()}
                                        title={"What's a dotScore?"}
                                        subtitle={"general"}
                                        icon={<Icon icon={"ic:baseline-credit-score"} />}
                                        desc={
                                            <>
                                                <p>The dotScore is a score from 1 to 100 that represents how users feel about a product. It is calculated from a dataset of all the product reviews collected from major retailers. </p>

                                                <p>Factors influencing the dotScore include reviewer verification status, review content, and tone. Shopdot ensures accuracy by filtering out suspicious reviews, including bots, duplicates, and non-English content⁠.</p>
                                            </>
                                        }
                                        onAccordionClick={() => handleAccordionClick("0")}
                                    />
                                    <LearnAccordion
                                        itemKey="1" // Ensure the key is a string
                                        activeKey={activeKey.toString()} // Convert to string if activeKey is a number
                                        title="What's white cast"
                                        subtitle="sunscreens"
                                        icon={<Icon icon={"ph:sunglasses-light"} />}
                                        desc={<>
                                            <p>White cast is the white film that many popular sunscreens leave on your skin. However, not all sunscreens have white cast which is why we created the white cast score. It gives you the most accurate measurement of if the sunscreen has white cast ever!</p>
                                        </>}
                                        onAccordionClick={() => handleAccordionClick("1")}
                                    />
                                    </Accordion>


                                </div>

                                <div 
                                    onClick={() => setShowLearn(false)}
                                    className="btn btn-dark text-white btn-default flex items-center justify-center gap-1 h-12">
                                        Get back to shopping! 
                                        <Icon icon={"ph:arrow-right"}/>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={show} className="SignIn-Modal">
                        <Modal.Body>
                            <div className="Gradients-content">
                                <div className="Gradients-Title-Icons">
                                    <Link to="#" onClick={() => handleClose()}>
                                        <Icon icon="octicon:x-24" />
                                    </Link>
                                </div>
                            </div>
                            <SignIn handleClose={handleClose} />
                        </Modal.Body>
                    </Modal>
                </div>
            </Container>

        </header>

    );
}


function LearnAccordion({ itemKey, activeKey, title, subtitle, desc, icon, onAccordionClick }) {

    return(
    <Accordion.Item eventKey={itemKey.toString()} onClick={() => onAccordionClick(itemKey)} className="shadow-lg rounded-xl">
        <Accordion.Header>

        <div className="ReviewAccordionWrap">
          <div className="bg-black text-white h-12 w-12 flex items-center justify-center text-3xl rounded-xl" >{icon}</div>
          <div>
              <h5>{title}</h5>
            <h6>
                {subtitle}
            </h6>
          </div>
        </div>

           
        </Accordion.Header>


        {activeKey === itemKey && (

            <Accordion.Body >
                <div className="text-sm flex flex-col space-y-2">{desc}</div>
            </Accordion.Body>
        )
        }
    </Accordion.Item>
    )
}
export default Header;
