import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Container, Image, Modal, Nav, Tab } from "react-bootstrap";
import ProductActivity from "../../component/ProductActivity/ProductActivity";
import ReviewsDetails from "../../component/ReviewsDetails/ReviewsDetails";
import Gradients from "../Gradients/Gradients";
import "./ReviewDetail.css";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useQuery } from "react-query";
import { db } from "../../utils/firebase-config";
import { set } from "react-ga";

const fetchReviewById = async (productId) => {
  const productDocRef = doc(db, "products", productId);
  const [productDoc, categoriesSnapshot] = await Promise.all([
    getDoc(productDocRef),
    getDocs(query(collection(productDocRef, "categories"))),
  ]);

  const productData = productDoc.data();
  let categoriesData = categoriesSnapshot.docs.map((doc) => doc.data())[0];
  return {
    ...productData,
    categories: categoriesData,
  };
};

const reviewByCategory = async (productId, type, reviewLimit, filterId, lastReview = null) => {
  const productDocRef = doc(db, "products", productId);
  let queryRef;

  // Define base query for reviews
  let baseQuery = query(collection(productDocRef, "reviews"), where("non_duplicate", "==", true), orderBy("date", "desc"), limit(reviewLimit));


  if (filterId === "light" || filterId === "medium" || filterId === "dark") {
    const filterLabel = `${filterId}_label`;
    baseQuery = query(baseQuery, where(filterLabel, "==", true));
  }

  if (type === "texture") {
    // Texture-specific query
    queryRef = query(baseQuery, where("texture_label", "==", true));
  } else if (type === "removes_makeup") {
    // Removes makeup-specific query
    queryRef = query(baseQuery, where("removes_makeup", "==", true));
  }  else if (type === "irritation") {
    // Removes makeup-specific query
    queryRef = query(baseQuery, where("irritation", "==", true));
  } else if (type === "whitecast") {
    // Removes makeup-specific query
    queryRef = query(baseQuery, where("whitecast_label", "==", true));
  } else if (type === "makeup") {
    // Removes makeup-specific query
    queryRef = query(baseQuery, where("makeup_label", "==", true));
  } else {
    // Generic case for other types like hydration or acne
    const labelField = type === "hydration" ? "works_label" : type === "acne" ? "acne_label" : "smell_label";
    queryRef = query(baseQuery, where(labelField, "==", true));
    // Optionally add orderBy here if you have a corresponding confidence score or other metric
  }

  const reviewsSnapshot = await getDocs(queryRef);
  const reviews = reviewsSnapshot.docs.map((doc) => ({ ...doc.data(), reviewId: doc.id }));
  return reviews;
};


function ReviewDetail() {
  const { id, type } = useParams();
  const [reviewLimit, setReviewLimit] = useState(4);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const [filterId, setFilterId] = useState("all");
  const [reviewList, setReviewList] = useState([]);
  const {
    data: review,
    isLoading,
    isError,
  } = useQuery(["review", id], () =>
    fetchReviewById(id)
  );
  let count;

  const {
    data: reviews,
    isReviewLoading,
    isReviewError,
    refetch: refetchReviews, // Destructure the refetch function
  } = useQuery(["reviews", id, type, reviewLimit, filterId], () =>
    reviewByCategory(id, type, reviewLimit, filterId)
  );

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    if (reviews) {
      // Assuming 'reviews' is an array of new reviews fetched
      // setReviewList((prevReviews) => {
      //   // Create a new Set to automatically remove duplicates based on reviewId
      //   const allReviews = new Set([...prevReviews, ...reviews]);
      //   return Array.from(allReviews);
      // });
      setReviewList(reviews);
    }
  }, [reviews]);

  const product_type = review?.product_type;

  // useEffect(() => {
  //   setReviewList(reviews);
  // }, [reviewList]);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (type === "removes_makeup") {
    count = review?.removes_makeup_count;
  } else if (type === "irritation") {
    count = review?.irritation_count;
  } else if (type === "whitecast") {
    count = review?.whitecast_count;
  } else if (type === "makeup") {
    count = review?.makeup_count;
  }
  else {
    count = review?.categories?.categories[type].count;
  }

  let score = Math.round(review?.[`${type}_score`]);
  if (type === "removes_makeup") {
    score = Math.round(review?.removes_makeup_score);
  } else if (type === "whitecast") {
    score = Math.round(review?.whitecast_score);
  } else if (type === "makeup") {
    score = Math.round(review?.makeup_score);
  } 

  const handleFilterChange = (selectedFilterId) => {
    setReviewList([]);
    setFilterId(selectedFilterId);
    refetchReviews()
  };

  const preview = review?.categories[`${type}_preview`];
  
  return (
    <>
    {isLoading ? <div className="loading"></div> : (
    <div className="review-content">
      <Container className="Review-Box">
        <div className={`Review-Box ${isDesktop ? "" : "default-card"}`}>
          <Link to="#" className="back-btn back-btn-reletive" onClick={() => navigate(-1)}>
            <Icon icon="ph:arrow-left" />
          </Link>
          <div className="Review-Box-Title">
          {isDesktop && (
              <div className="score-card-score">
                {type !== "makeup" && type !== "irritation" ? (
                  <h4>
                    {score}{" "}
                    <span>
                      <Icon icon="mdi:chevron-up-circle-outline" />
                    </span>
                  </h4>
                ) : (
                  <span>
                    <Icon icon="mdi:check-circle" />
                  </span>
                )}
                {type !== "makeup" && type !== "irritation" && <i>dotScore</i>}
              </div>
            )}
            <div className="Review-Box-Info">
            <h5>{type === "removes_makeup" ? "removes makeup" : type}</h5>
              <h6>
                <span>
                  based on {count} reviews
                </span>
              </h6>
            </div>
            {!isDesktop && (
              <div className="score-card-score">
                {review && (type !== "makeup" && type !== "irritation") ? (
                  <h4>
                    {score}{" "}
                    <span>
                      <Icon icon="mdi:chevron-up-circle-outline" />
                    </span>
                  </h4>
                ) : (
                  review && (
                    <span>
                      <Icon icon="mdi:check-circle" />
                    </span>
                  )
                )}
                {review && (type !== "makeup" && type !== "irritation") && <i>dotScore</i>}
              </div>
            )}
          </div>
          <ul>
            <li>
              {preview}{" "}
              <Link to="#">See More</Link>
            </li>
          </ul>
          {type === "whitecast" && (<fieldset className='fieldborder-skintone'>
              <legend align="center" className="skintone-box">
                new! 🎉 real people rated for their skin tone
              </legend>
              <div className="skintone-box-whitecast">
                <div className="skintone-stack">
                  <div className="skintone-box-light-skintone">
                    {review?.light_score?.toFixed()}
                  </div>
                  <div className="skintone-title">
                    light
                  </div>
                  <div className="skintone-subtitle">
                    with {review?.light_count} reviews
                  </div>

                </div>
                <div className="skintone-stack">
                  <div className="skintone-box-medium-skintone">
                    {review?.medium_score?.toFixed()}
                  </div>
                  <div className="skintone-title">
                    medium
                  </div>
                  <div className="skintone-subtitle">
                    with {review?.medium_count} reviews
                  </div>
                </div>
                <div className="skintone-stack">
                  <div className="skintone-box-dark-skintone">
                    {review?.dark_score?.toFixed()}
                  </div>
                  <div className="skintone-title">
                    dark
                  </div>
                  <div className="skintone-subtitle">
                    with {review?.dark_count} reviews
                  </div>
                </div>
              </div>

          </fieldset>)}
          <Link to={`/ProductDetail/${id}`} className="score-card-wrap score-card-bg mb-3" style={{ maxWidth: '358px' }}>
            <div className="score-card-img">
              <Image src={review?.colored_photo} />
            </div>
            <div className="score-card-info">
              <h5 className="truncate-text">{review?.name}</h5>
              <p>{review?.brand}</p>
              <h6>${review?.price.toFixed(2)}</h6>
            </div>
            <div className="score-card-score" style={{borderColor: 'white'}}>
              <h4 style={{color: 'black'}}>
                {review?.dotscore.toFixed()}{" "}
                <span style={{color: 'black'}}>
                  <Icon icon="mdi:chevron-up-circle-outline" />
                </span>
              </h4>
              <i style={{color: 'black'}}>dotScore</i>
            </div>
          </Link>
          {/* <Link
            to="#"
            onClick={handleShow}
            className="btn btn-gray-border btn-Gradients w-100"
          >
            Which ingredients effect this?{" "}
            <i>
              <Icon icon="formkit:arrowright" />
            </i>
          </Link> */}
        </div>
      </Container>
      <div className="reviews-content-info">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="produts-tabs-wrap">
          <Nav variant="pills" className="produts-tabs">
            <Nav.Item>
              <Nav.Link eventKey="first">Reviews</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Activity</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Container>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              {isReviewLoading ? <div className="loading"></div> : (
                <ReviewsDetails
                  data={reviewList}
                  setReviewLimit={setReviewLimit}
                  type={product_type}
                  onFilterChange={handleFilterChange}
                />
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <ProductActivity productId={id}/>
            </Tab.Pane>
          </Tab.Content>
        </Container>
      </Tab.Container>
      <Modal show={show}>
        <Modal.Body>
          <Gradients handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </div>
    </div>)}
    </>
  );
}

export default ReviewDetail;
