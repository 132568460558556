import './PriceHighEnd.css';
import { ProductContext } from '../../Pages/ProductDetail/ProductDetail';
import { useContext, useEffect, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

function PriceHighEnd({ bundle_price }) {
    const context = useContext(ProductContext);
    const { product } = context || {};
    const [sliderValue, setSliderValue] = useState(0);

    useEffect(() => {
        if (product) {
            const pricePerOunce = product.price / product.ounces;
            const newSliderValue = calculateSliderValue(pricePerOunce);
            setSliderValue(newSliderValue);
        } else if (bundle_price) {
            setSliderValue(calculateBundleSliderValue(bundle_price));
        }
    }, [product, bundle_price]);

    const pricePerOunce = product ? product.price / product.ounces : 0;

    // Function to calculate slider value from price per ounce
    const calculateSliderValue = (pricePerOunce) => {
        if (pricePerOunce <= 20) {
            // Scale linearly from 0 to 50
            return (pricePerOunce / 20) * 50;
        } else {
            // Scale linearly from 21 to 100
            return 50 + ((pricePerOunce - 20) / (140 - 20)) * 50;
        }
    };

    // Function to calculate slider value for bundle price
    const calculateBundleSliderValue = (bundle_price) => {
        if (bundle_price <= 150) {
            // Scale linearly from 35 to 150
            return ((bundle_price - 35) / (150 - 35)) * 100;
        } else {
            return 100;
        }
    };

    if (!bundle_price) {
        return (
            <div className='most-mentioned-overview'>
                <div className='PriceHighEnd-Title'>
                    <div className="money-wrapper">
                        <Icon icon="ph:currency-circle-dollar" className="icon" />
                    </div>
                    <h6>
                        <b>${product?.price}</b> for {product?.ounces} oz.
                        <span> buy now on <Link to="#">amazon.com</Link></span>
                    </h6>
                </div>
                <div className='PriceCustomMain'>
                    <h6>that’s <b>${(product?.price / product?.ounces)}/oz.</b></h6>
                    <Slider
                        className='rcs-slider'
                        min={0}
                        max={100}
                        value={sliderValue}
                        onChange={newValue => setSliderValue(newValue)}
                    />
                    <p><small>Low: $0.92/oz.</small> <small>High: $138.99/oz.</small></p>
                </div>
            </div>
        );
    }

    return (
        <div className='most-mentioned-overview' style={{paddingTop: '0px'}}>
            <div className='PriceHighEnd-Title'>
                <div className="money-wrapper">
                    <Icon icon="ph:currency-circle-dollar" className="icon" />
                </div>
                <h6>
                    <b>${bundle_price}</b> per month
                    <span> buy now on shopdot<Link to="#"></Link></span>
                </h6>
            </div>
            <div className='PriceCustomMain'>
                <h6>that’s <b>${bundle_price} per month</b></h6>
                <Slider
                    className='rcs-slider'
                    min={0}
                    max={100}
                    value={sliderValue}
                    onChange={newValue => setSliderValue(newValue)}
                />
                <p><small>Low: $35/month.</small> <small>High: $150/month.</small></p>
            </div>
        </div>
    );
}

export default PriceHighEnd;
