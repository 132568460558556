import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./FilterReviews.css";

function FilterReviews({ filter, handleClose }) {
  const [filterData, setFilterData] = useState({
    dry: filter?.dry || false,
    oily: filter?.oily || false,
    normal: filter?.normal || false,
    order: filter?.order || "",
  });

  const onHnadleSelectSkinType = (e) => {
    setFilterData({
      ...filterData,
      [e.target.value]: !filterData[e.target.value],
    });
  };

  const onSetOrder = (val) => {
    setFilterData((prev) => {
      return { ...prev, order: val };
    });
  };

  const onCheckFilters = () => {
    let filterCount = 0;
    Object.keys(filterData).map((keyName, i) => {
      if (keyName == "order" && filterData[keyName] !== "") {
        filterCount = filterCount + 1;
      }
      if (filterData[keyName] === true) {
        filterCount = filterCount + 1;
      }
    });
    return filterCount;
  };

  // console.log({ filterData })
  return (
    <div className="WhatsSkinType-popup">
      <h4>Filter & Sort</h4>
      <div className="default-card mb-3">
        <h6>
          What’s your skin type?{" "}
          <small>we’ll suggest the top-rated moisturizers for you</small>
        </h6>

        <div className="skintype-wrap">
          <label htmlFor="Dry-skintype">
            <input
              type="checkbox"
              name="skintype"
              id="Dry-skintype"
              value="dry"
              onChange={onHnadleSelectSkinType}
              checked={filterData.dry}
            />
            <div className="skintype-box">
              <span>
                <Icon icon="ph:circle-dashed" />
              </span>
              <p>Dry</p>
            </div>
          </label>
          <label htmlFor="Oily-skintype">
            <input
              type="checkbox"
              name="skintype"
              id="Oily-skintype"
              value="oily"
              onChange={onHnadleSelectSkinType}
              checked={filterData.oily}
            />
            <div className="skintype-box">
              <span>
                <Icon icon="ph:circle-half-tilt" />
              </span>
              <p>Oily</p>
            </div>
          </label>
          <label htmlFor="Normal-skintype">
            <input
              type="checkbox"
              name="skintype"
              id="Normal-skintype"
              value="normal"
              onChange={onHnadleSelectSkinType}
              checked={filterData.normal}
            />
            <div className="skintype-box">
              <span>
                <Icon icon="material-symbols:circle-outline" />
              </span>
              <p>Combination</p>
            </div>
          </label>
        </div>
      </div>
      <div className="default-card">
        <h6>other preferences</h6>
        <label htmlFor="highlyrecommends" className="custom-checkbox">
          <input
            type="checkbox"
            name="preferences "
            id="highlyrecommends"
          />
          <span><Icon icon="ic:round-check" /></span>
          <p>highly recommends</p>
        </label>
        <label htmlFor="notrecommend" className="custom-checkbox">
          <input
            type="checkbox"
            name="preferences "
            id="notrecommend"
          />
          <span><Icon icon="ic:round-check" /></span>
          <p>doesn’t recommend</p>
        </label>
      </div>
      <Button
        variant=""
        className="btn-black btn-apply"
        onClick={() => handleClose(filterData)}
      >
        Apply{" "}{onCheckFilters(filterData)}{" "}
        {onCheckFilters(filterData) <= 1 ? "filter" : "filters"}
      </Button>
    </div>
  );
}

export default FilterReviews;
