import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const Paragraph = ({ item, classname, handleShow, moreLink }) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const paragraphRef = useRef();

  useEffect(() => {
    const containerHeight = paragraphRef.current.clientHeight;
    const contentHeight = paragraphRef.current.scrollHeight;

    setIsOverflowing(contentHeight > containerHeight);
  }, [item, paragraphRef]);

  return (
    <>
      <p className={classname} ref={paragraphRef}>
        {item}
      </p>
      {isOverflowing ? (
        <p>
          <Link className="seemore-link" to={moreLink} onClick={() => handleShow(item)}>
            See more
          </Link>
        </p>
      ) : (
        ""
      )}{" "}
    </>
  );
};

export default Paragraph;
