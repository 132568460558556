import './SimilarProducts.css';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { db } from "../../utils/firebase-config";
import { useContext } from 'react';
import { ProductContext } from '../../Pages/ProductDetail/ProductDetail';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Image } from 'react-bootstrap';


const fetchAlternatives = async (score, type) => {
    const productsCollectionRef = collection(db, "products");

    const higherScoreQuery = query(
        productsCollectionRef,
        where("dotscore", ">", score),
        where("product_type", "==", type),
        orderBy("dotscore", "asc"),
        limit(2)
    );

    const lowerScoreQuery = query(
        productsCollectionRef,
        where("dotscore", "<", score),
        where("product_type", "==", type),
        orderBy("dotscore", "desc"),
        limit(2)
    );

    const [higherProductsSnapshot, lowerProductsSnapshot] = await Promise.all([
        getDocs(higherScoreQuery),
        getDocs(lowerScoreQuery)
    ]);

    let similarProductsData = [];

    const higherProductsData = higherProductsSnapshot.docs.map((doc) => {
        return { ...doc.data(), productId: doc.id };
    });
    similarProductsData.push(...higherProductsData);

    const lowerProductsData = lowerProductsSnapshot.docs.map((doc) => {
        return { ...doc.data(), productId: doc.id };
    });
    similarProductsData.push(...lowerProductsData);

    return similarProductsData;
}

function getScoreClassIcon(score) {
    if (score > 95) {
        return { className: 'green-box', icon: 'ph:trophy' };
    } else if (score < 55) {
        return { className: 'yellow-box', icon: 'ph:toilet-paper' };
    } else {
        return '';
    }
}

function SimilarProducts({hydration_score, type}) {
    const [alternatives, setAlternatives] = useState([]);
    const [showAll, setShowAll] = useState(false);

    const {
        data: similarProducts,
        isLoading,
        isError,
    } = useQuery(["similarProducts", hydration_score], () =>
        fetchAlternatives(hydration_score, type)
    );

    useEffect(() => {
        if (similarProducts) {
            const newProducts = similarProducts.filter(
                (item) =>
                    !alternatives.some((existingItem) => existingItem.productId === item.productId)
            );
            if (newProducts.length > 0) {
                setAlternatives((prev) => [...prev, ...newProducts]);
            }
        }
    }, [similarProducts, alternatives]);

    return (
        <>
            <div className='SimilarProducts-Info default-card'>
                <h3 className="CardTitleh3">Try something else?</h3>
                {alternatives.slice(0, showAll ? alternatives.length : 2).map((altProduct, index) => (
                    altProduct?.show !== 0 && (
                        <Link key={index} to={`/ProductDetail/${altProduct?.productId}`}>
                            <div className="score-card-wrap mb-2.5 score-card-bg" style={{border: "1px solid #EEE"}}>
                                <div className="score-card-img">
                                    <Image src={altProduct.plain_photo_1} />
                                </div>
                                <div className="score-card-info">
                                    <h5 className="truncate-text">{altProduct.name}</h5>
                                    <p>{altProduct.brand} <span>{/* #tag here */}</span></p>
                                    <h6>${altProduct.price}</h6>
                                </div>
                                <div className="score-card-score-general bg-white">
                                    <h4>{altProduct.dotscore.toFixed()} <span><Icon icon="mdi:chevron-up-circle-outline" /></span></h4>
                                    <i>dotScore</i>
                                    <small className={getScoreClassIcon(altProduct.dotscore.toFixed()).className}><Icon icon={getScoreClassIcon(altProduct.dotscore.toFixed()).icon} /></small>
                                </div>
                            </div>
                        </Link>
                    )
                ))}
                <div className="text-center">
                    <button className="btn btn-black" style={{ borderRadius: '12px' }} onClick={() => setShowAll(!showAll)}>
                        {showAll ? "See Less" : "See More"}
                    </button>
                </div>
            </div>
        </>
    );
}

export default SimilarProducts;
