import React, { useState, useEffect, createContext, useContext } from 'react';

// Create a context for the shopping cart
const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (product) => {
    setCart((prevCart) => {
      const existingProductIndex = prevCart.findIndex(p => p.name === product.name);
      if (existingProductIndex !== -1) {
        const updatedCart = [...prevCart];
        updatedCart[existingProductIndex].quantity += 1;
        return updatedCart;
      } else {
        return [...prevCart, { name: product.name, brand: product.brand, website: product.website, price: product.price, oz: product.oz, quantity: 1 }];
      }
    });
  };

  const removeFromCart = (productName) => {
    setCart((prevCart) => {
      return prevCart.filter(product => product.name !== productName);
    });
  };

  const updateQuantity = (productName, quantity) => {
    setCart((prevCart) => {
      return prevCart.map(product => 
        product.name === productName ? { ...product, quantity: product.quantity + quantity } : product
      ).filter(product => product.quantity > 0);
    });
  };

  console.log(cart);

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, updateQuantity }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
