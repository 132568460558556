// BundleCard.jsx
import React from 'react';
import './BundleCard.css';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DotScore from '../DotScore/DotScore';

const BundleCard = ({ data }) => {
  const { image, name, price, id } = data;

  return (
    <div className="bundle-card">
        <a href={`/BundleDetail/${id}`} target="_blank" rel="noopener noreferrer">
      <div className="bundle-image">
      <div className="bundle-image-top">
          <div className="image-container-first">
            <Image className="image-first" src={data?.face_wash?.plain_photo_1} alt={name} />
          </div>
          <div className="image-container-second">
            <Image className="image-second" src={data?.moisturizer?.plain_photo_1} alt={name} />
          </div>
        </div>
        <div className="bundle-image-bottom">
          <div className="image-container-third">
            <Image className="image-third" src={data?.sunscreen?.plain_photo_1} alt={name} />
          </div>
          <div className="image-container-fourth">
            <Image className="image-fourth" src={data?.moisturizer?.colored_photo} alt={name} />
          </div>
        </div>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <div className="bundle-info">

          <h3 className="bundle-name">{name}</h3>
          <div className="bundle-brand"> {data?.face_wash?.brand}, {data?.moisturizer?.brand}, {data?.sunscreen?.brand}</div>
          <p className="bundle-price">${price.toFixed(2)}/m or ${(price*2).toFixed(2)}</p>

        </div>

        <DotScore dotscore={Math.round(data?.dotscore)} grey={true} />
      </div>
      <div className="bundle-action">
        <span className="top-rated">top-rated {name} </span> • {data?.blurb}
      </div>
        </a>
    </div>
  );
};

export default BundleCard;
