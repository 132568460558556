import React from "react";
import "./ScoreWorks.css";
import { Link } from "react-router-dom";

function ScoreWorks() {
  return (
    <div className="score-works-container">
      <div className="whats-title">What's a dotscore?</div>
      
      <ul>
        <li>A dotScore is similar to a Tomatometer score, but for cosmetics. It provides an idea of how <em>fresh</em> a moisturizer is, both overall and by attribute, and also for your specific skin type.</li>
        <li>We've calculated these dotScores by analyzing over 500,000 reviews. We examine reputable sources for reviews left by verified purchasers, focusing on honest feedback that shares an individual's complete experience with the product.</li>
        <li>On shopdot, you can explore the reviews behind the dotScores. This allows you to see what others are saying. We've also added tags, making it easy for you to find verified reviews or those relevant to your skin type.</li>
        <li>Don’t agree with a score? <Link to="https://calendly.com/d/5d7-sbg-2s6">Tell us!</Link></li>
      </ul>
      <h3>What are we trying to do?</h3>
      <p>To provide personalized skincare suggestions based on skin type and preferences, we’ve analyzed over 500,000 reviews to study how different moisturizers impact the skin. The dotscore, ranging from 1 to 100, reflects sentiment towards a product and is calculated from a comprehensive dataset of reviews sourced from reputable platforms like Target, Walmart, Amazon, and Google Shopping. Factors influencing the dotscore include reviewer verification status, review content, and tone. shopdot ensures accuracy by filtering out suspicious reviews, including bots, duplicates, and non-English content, striving to be the most reliable product assessment platform.</p>

      <h3>Top-Rated & Below Average:</h3>

      <ul>
        <li>Top-Rated: 90+ (highly selective, the best, premium)</li>
        <li>Below-Average: Still a good product, may work for you. But shopdot features a select number so the top-trending moisturizers & this one may be below the average of the whole market - again, overall for an attribute or for your skin type.</li>
      </ul>
    </div>
  );
}

export default ScoreWorks;
