import React from "react";
import './CreatorCard.css'
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Image } from "react-bootstrap";


function CreatorCard({ data }) {
    const navigate = useNavigate();
    const handleShareClick = async (e) => {
        e.preventDefault();

        const title = 'Your Title';  // Replace with your title logic
        const url = 'Your URL';      // Replace with your URL logic

        if (navigator.share) {
            try {
                await navigator.share({
                    title,
                    url,
                });
            } catch (error) {
                console.error('Error sharing:', error);
            }
        }
    };

    const derm = data?.dermatologist === 1 ? 'dermatologist •' : '';

    const skin_type = data?.skin_type === 1 ? 'dry skin' : data?.skin_type === 2 ? 'oily skin' : data?.skin_type === 3 ? 'combination/normal skin' : '';
    return (
        <div className="CreatorCard-Card default-card">
            <div className="CreatorCardHeader">
                <Link to="#" onClick={() => navigate(-1)}>
                    <Icon icon="ph:arrow-left" />
                </Link>
                <Link to="#" onClick={handleShareClick} className="share-button">
                    <Icon icon="solar:share-linear" />
                </Link>
            </div>
            <div className="CreatorProfile">
                <i><Image src={data?.profile_pic} /></i>
                <h5>@{data?.creator} {data?.verified && <span><Icon icon="gravity-ui:seal-check" /></span> } </h5>
                <h6>{derm} <b> {skin_type}</b></h6>
                <p>{data?.bio} {data?.blog && <Link to={data?.blog}> check out my blog</Link>}</p> 
                <ul>
                    {/* {<li><Link to="#"><Icon icon="ph:coffee" /></Link></li>} */}
                    {data?.instagram_link && <li><Link to={data?.instagram_link}><Icon icon="ri:instagram-line" /></Link></li>}
                    {data?.tiktok_link && <li><Link to={data?.tiktok_link}><Icon icon="ph:tiktok-logo" /></Link></li>}
                    {data?.youtube_link && <li><Link to={data?.youtube_link}><Icon icon="ph:youtube-logo" /></Link></li>}
                </ul>
            </div>
        </div>
    );
}

export default CreatorCard;
