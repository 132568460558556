import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import IngredientsCard from "../../component/IngredientsCard/IngredientsCard";
import SimilarProducts from "../../component/SimilarProducts/SimilarProducts";
import './Gradients.css'
import { collection, doc, DocumentSnapshot, getDoc, getDocs, query } from "firebase/firestore";
import { db } from "../../utils/firebase-config";
import { useQuery } from "react-query";

const fetchFeatureByProduct = async (productId, type, ingredientsLimit) => {
    const productDocRef = doc(db, "products", productId);
    const [
      productDoc,
      categorySnapshot,
    ] = await Promise.all([
      getDoc(productDocRef),
      getDocs(
        query(collection(productDocRef, "categories"))
        ),
        
      ]);
      
      const productData = productDoc.data();
      
      const categoryData = {...categorySnapshot.docs[0].data(), categoryId: categorySnapshot.docs[0].id };

    const ingredients = [];
    let count = 1
    for (const ingredient of categoryData?.categories[type].ingredients) {
        const ingredientDocRef = doc(productDocRef, "ingredients", `${ingredient}`);
        const ingredientSnapshot = await getDoc(query(ingredientDocRef))
        if(ingredientSnapshot.data()) {
            ingredients.push({ ...ingredientSnapshot.data() })
            if(count === ingredientsLimit) break
            count++
        }
    }
   
    return {
      ...productData,
      ingredients: ingredients,
      feature: categoryData
    };
  };

function Gradients({ handleClose }) {
    const { id, type } = useParams()
    const [ingredientsLimit, setingredientsLimit] = useState(4);
    const {
        data: feature,
        isLoading,
        isError,
      } = useQuery(["feature", id, type, ingredientsLimit], () => fetchFeatureByProduct(id, type, ingredientsLimit));     
    return (
        <div className="Gradients-content">
            <div className="Gradients-Title-Icons">
                <Link to="#" onClick={handleClose}>
                    <Icon icon="octicon:x-24" />
                </Link>
            </div>
            <div className="IngredientsCard-Main">
                <h3>Which ingredients affect hydration?</h3>
                  <IngredientsCard items={feature?.ingredients} setingredientsLimit={setingredientsLimit} isLoading={isLoading} />
            </div>
            <div className="SimilarProducts-Main">
                {/* <h3>Try Something else?</h3> */}
                <SimilarProducts hydration_score={feature?.hydration_score} />
            </div>
        </div>
    );
}

export default Gradients;
