import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import ProductActivityCard from "../ProductActivityCard/ProductActivityCard";
import "./ProductActivity.css";
import { useQuery } from "react-query";
import {  collection,  doc,  getDoc,  getDocs,  limit,  orderBy,  query, startAfter, where, } from "firebase/firestore";
import { db } from "../../utils/firebase-config";
import InfiniteScroll from "react-infinite-scroll-component";
import NoInfoCard from "../NoInfoCard/NoInfoCard";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function getTopKeys(obj, val) {
  // Create an array of key-value pairs
  if (!obj || typeof obj !== "object") {
    return []; // Return an empty array if obj is null or not an object
  }
  const entries = Object.entries(obj);

  // Filter out keys starting with 'highlight' or 'concern'
  let filteredEntries;
  if (val === "feature") {
    filteredEntries = entries.filter(
      ([key]) => key.startsWith("highlight") || key.startsWith("concern")
    );
  } else {
    filteredEntries = entries.filter(
      ([key]) => !key.startsWith("highlight") && !key.startsWith("concern")
    );
  }

  // Sort the filtered array based on values in descending order
  const sortedEntries = filteredEntries.sort((a, b) => b[1] - a[1]);

  // Take the top two keys
  const topKeys = sortedEntries;

  // Extract only the keys from the result
  const result = topKeys.map(([key]) => key);

  return result;
}

const fetchRelatedData = async (postsSnapshot) => {
  let postsData = [];
  for (const post of postsSnapshot) {
    let featureArr = getTopKeys(post.data().mentions, "feature");
    let tagsArr = getTopKeys(post.data().mentions, "tag");

    const productCollectionRef = doc(db, "products", post.data().product);
    const productSnapshot = await getDoc(productCollectionRef);

    let featureList = [];
    if (featureArr.length > 0) {
      for (const feature of featureArr) {
        const featureCollectionRef = doc(
          productCollectionRef,
          "feature",
          feature
        );
        const featureSnapshot = await getDoc(featureCollectionRef);
        featureList.push(featureSnapshot.data());
      }
    }

    const creatorCollectionRef = doc(
      db,
      "creators",
      post.data().creator.replace("@", "")
    );
    const creatorSnapshot = await getDoc(creatorCollectionRef);
    // console.log("b", postsData);
    postsData.push({
      ...post.data(),
      postId: post.id,
      productDetails: productSnapshot.data(),
      creatorDetails: creatorSnapshot.data(),
      featureList: featureList,

    });
  }
  return postsData;
}

const fetchPosts = async (postslimit, startAfterDoc, searchTerm, productId) => {
  // Fetch products with the specified postslimit
  const postsCollectionRef = collection(db, "posts");
  let queryOptions
  
  let totalQuery = query(postsCollectionRef);
  const totalPostsSnapshot = await getDocs(totalQuery);

  if (productId) {
    queryOptions = query(
        postsCollectionRef,
        where("product", "==", productId),
        orderBy('date', 'desc'),
        limit(postslimit)
    );
  } else {
    if (startAfterDoc) {
      queryOptions = query(
        postsCollectionRef,
        limit(postslimit),
        orderBy('date', 'desc'),
        startAfter(startAfterDoc)
      );
    } else {
      queryOptions = query(postsCollectionRef, limit(postslimit),
       orderBy('date', 'desc')
       );
    }
  }

  if (searchTerm) {

    const queryOptions = query(
      postsCollectionRef,
      where('creator_lower', '>=', searchTerm), // Match summary containing the searchTerm
      where('creator_lower', '<=', searchTerm + '\uf8ff'), // '\uf8ff' is a high surrogate code point
      orderBy('creator_lower', "desc"),
      orderBy('date', 'desc'),
      limit(postslimit),     
    );

    const summaryQueryOptions = query(
      postsCollectionRef,
      // where("creator", "==", searchTerm), // Match exact creator
      where('summary_lower', '>=', searchTerm), // Match summary containing the searchTerm
      where('summary_lower', '<=', searchTerm + '\uf8ff'), // '\uf8ff' is a high surrogate code point
      orderBy('summary_lower', "desc"),
      orderBy('date', 'desc'),
      limit(postslimit)
    );

    const [creatorSnapshot, summarySnapshot] = await Promise.all([
      getDocs(queryOptions),
      getDocs(summaryQueryOptions),
    ]);

    const postsSnapshot = {
      docs: [...creatorSnapshot.docs, ...summarySnapshot.docs],
    };

    // Remove duplicates if any
    const uniqueProducts = Array.from(new Set(postsSnapshot?.docs.map(doc => doc.id)))
      .map(id => postsSnapshot.docs.find(doc => doc.id === id));
    const postsData = await fetchRelatedData(uniqueProducts)

    return {
      postsData: postsData,
      lastDoc: postsSnapshot.docs[postsSnapshot.docs.length - 1],
      totalDoc: totalPostsSnapshot.docs.length
    };
  }

  const postsSnapshot = await getDocs(queryOptions);
  // console.log("a", postsSnapshot.docs);
  const postsData = await fetchRelatedData(postsSnapshot.docs)
  // Iterate through each product 
  return {
    postsData: postsData,
    lastDoc: postsSnapshot.docs[postsSnapshot.docs.length - 1],
    totalDoc: totalPostsSnapshot.docs.length
  };
};

function ProductActivity({ productId }) {
  const postslimit = 8;
  const [startAfterDoc, setStartAfterDoc] = useState(null);
  const [postsList, setPostsList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [numberOfDoc, setNumberOfDoc] = useState();
  const [hasMore, setHasMore] = useState(true);
  const auth = getAuth();
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const {
    data: posts,
    isLoading,
    isError,
  } = useQuery(["product", postslimit, startAfterDoc, searchTerm, productId], () =>
      fetchPosts(postslimit, startAfterDoc, searchTerm, productId)
  );

  useEffect(() => {
    // check if there is a user
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setIsUserSignedIn(true);
        setCurrentUser(user);
      } else {
        // User is signed out
        setIsUserSignedIn(false);
        setCurrentUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (posts) {
      const newPosts = posts.postsData.filter(
        (post) =>
          !postsList.some((existingPost) => existingPost.postId === post.postId)
      );
      if (newPosts.length > 0) {
        setPostsList((prev) => [...prev, ...newPosts]);
      }
      setNumberOfDoc(posts.totalDoc)
    }
  }, [posts, postsList]);

  const handleSeeMore = () => {
    if (postsList.length === numberOfDoc) {
      setHasMore(false)
      return
    }
    if (posts && posts.lastDoc) {
      setStartAfterDoc(posts.lastDoc);
    }
  };



  const showLoading = isLoading
  const showNoInfoCard = !isLoading && postsList.length === 0;

  return (
    <>
      <div className="search-box">
        <input
          type="text"
          placeholder="search creators & more"
          onChange={(event) => {
            setPostsList([])
            setSearchTerm(event.target.value.toLowerCase())
          }}
        />
        <Link to="#">
          <Icon icon="prime:search" />
        </Link>
      </div>
        <InfiniteScroll
          dataLength={postsList?.length}
          next={() => handleSeeMore()}
          hasMore={hasMore}
          loader={showLoading ? <div className="loading my-4"></div>: null}
        >
          <div className="ProductActivityCardList">
            {postsList?.map((item, index) => (
              <ProductActivityCard data={item} key={index} isPostDetails={false} user={currentUser} />
            ))}
          </div>
        </InfiniteScroll>
        {showNoInfoCard && <NoInfoCard />} 
    </>
  );
}

export default ProductActivity;
