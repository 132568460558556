function getIconHighlight(title) {
    const highlightsMapping = {
        "Great For Acne": "mdi:face-outline",
        "Long Lasting": "mdi:clock-outline",
        "Great For Aging Skin": "mdi:account-outline",
        "Worth The Price": "mdi:cash-multiple",
        "Great For Sensitive Skin": "mdi:flower-tulip-outline",
        "Suitable For All Seasons": "mdi:weather-sunny",
        "Suitable For All Skin Types": "mdi:palette-outline",
        "Feels Luxurious": "mdi:diamond-stone",
        "Little Goes A Long Way": "mdi:ray-end-arrow",
        "Replenishes Skin": "mdi:water-outline",
        "Great In Hot Climate": "mdi:sunglasses",
        "Eczema Relief": "mdi:bandage",
        "Smooths Skin": "mdi:blur-linear",
        "Effectively Hydrates": "mdi:water",
        "Absorbs Well": "ion:water-outline",
        "Great Texture": "mdi:texture",
        "Light Weight Texture": "mdi:feather",
        "Gel Like Texture": "mdi:gel-cream",
        "Great Smell": "mdi:flower",
        "Great Under Makeup": "mdi:palette-advanced",
        "Great Packaging": "mdi:gift-outline",
        "No White Cast": "mdi:invert-colors-off",
        "Not Greasy": "mdi:bottle-tonic-plus-outline",
        "No Pilling": "mdi:ballot-recount-outline",
        "No Stinging Eyes": "mdi:eye-check-outline",
        "No Stinging Skin": "mdi:skin-outline",
        "No Irritation": "mdi:emoticon-happy-outline",
        "No Oily Residue": "mdi:oil-level",
        "Protected Skin": "mdi:umbrella-outline",
        "Blends Well": "mdi:blur",
        "Great Overall Sunscreen": "mdi:beach",
        "Gentle on Skin": "mdi:baby-face-outline",
        "Clean Ingredients": "mdi:leaf-maple",
        "Leaves Skin Glowing": "mdi:white-balance-sunny",
        "Matches Skin Tone": "mdi:palette-swatch",
        "Not Sticky": "mdi:hand-front-right-outline",
        "Great Coverage": "mdi:format-paint",
        "Dermatologist Recommended": "mdi:doctor",
        "Nourished Skin": "mdi:food-apple-outline",
        "Travel Friendly": "mdi:airplane-takeoff",
        "Provides Natural Look": "mdi:face-profile-woman",
        "No Fragrance": "mdi:flower",
      };

    // Return the icon ID if the title exists in the mapping, or a default icon ID otherwise
    return highlightsMapping[title] || "iconoir:check-circle";
};


function getIconConcern(title) {
    const concernsMapping = {
        "Causes Breakouts": "mdi:pimple",
        "Irritated Skin": "fluent:skin-24-regular",
        "Irritated Eyes": "ic:round-eye",
        "Bad Packaging": "mdi:package-variant-closed",
        "Unknown Amount Of Retinol": "mdi:help-circle-outline",
        "Not Worth The Money": "mdi:cash-remove",
        "Not Effective": "mdi:emoticon-sad-outline",
        "Dried Out Skin": "mdi:water-off-outline",
        "Makes Skin Oily": "mdi:oil-level",
        "Unknown Amount Of Hyaluronic Acid": "mdi:help-rhombus-outline",
        "Bad Under Makeup": "mdi:face-woman-outline",
        "Bad For Sensitive Skin": "mdi:alert-outline",
        "Doesn't Hydrate": "mdi:water-off",
        "Unpleasant Fragrance": "mdi:flower-poppy",
        "Greasy Texture": "mdi:hand-water",
        "Heavy Texture": "mdi:weight-kilogram",
        "Slimy Texture": "mdi:snail",
        "White Cast": "mdi:invert-colors",
        "Greasy Residue": "mdi:cream",
        "Pilling": "mdi:ballot-recount-outline",
        "Stinging Eyes": "fluent:eye-tracking-off-16-regular",
        "Didn't Protect Skin": "mdi:umbrella-closed-outline",
        "Doesn't Blend Well": "mdi:blur",
        "Not Gentle on Skin": "mdi:hand-back-left-outline",
        "Bad Ingredients": "mdi:biohazard",
        "Doesn't Match Skin Tone": "mdi:palette-swatch",
        "Formula Change": "mdi:flask-outline",
        "Bad Orange Tint": "mdi:palette-outline",
        "Bad Service": "mdi:account-cancel-outline",
        "Not Waterproof": "mdi:water-off-outline",
        "Didn't Absorb Well": "mdi:water-minus-outline",
        "Allergic Reaction": "mdi:alert-circle-outline",
        "Hard to Reapply": "mdi:reload-alert",
      };

    return concernsMapping[title] || "ph:warning-octagon";
}

  
  // Export the function so it can be imported elsewhere
  export { getIconConcern, getIconHighlight };