import React, { useState, useEffect }from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Image, Modal } from "react-bootstrap";
import "./ProductActivityCard.css";
import Paragraph from "../Paragraph/Paragraph";
import SimilarProducts from "../SimilarProducts/SimilarProducts";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../utils/firebase-config";
import SignIn from "../../Pages/SignIn/SignIn";

function ProductActivityCard({ data, isPostDetails, user }) {  
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasLiked, setHasLiked] = useState(false);
  const [hasDisliked, setHasDisliked] = useState(false);
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showMoreContent, setShowMoreContent] = useState(false);
  const [seeMoreContent, setSeeMoreContent] = useState("");
  const videoClassName = `Product-Activity-Card-Media ${
    isPlaying ? "playing" : ""
  }`;

  const togglePlay = () => {
    // play video
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  useEffect(() => {
    // getting if the post is liked or not
    const fetchLikesAndDislikes = async () => {
      try {
        const docRef = doc(db, "posts", data?.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setHasLiked(!!docSnap.data().likes[user?.uid]);
          setHasDisliked(!!docSnap.data().dislikes[user?.uid]);
        }
      } catch (error) {
        console.error("Error fetching like/dislike data:", error);
      }
    };

    fetchLikesAndDislikes();
  }, [user, data?.id]);

  const calculateDateDifferences = (dateString) => {
    // calculating dates
    const currentDate = new Date();

    const date = new Date(dateString * 1000); // Convert to milliseconds

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = currentDate - date;

    // Calculate the difference in days
    const daysDifference = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );

    // For months and years, it's a bit more complex
    let monthsDifference = 12 * (currentDate.getFullYear() - date.getFullYear()) + currentDate.getMonth() - date.getMonth();
    if (date.getDate() > currentDate.getDate()) {
        monthsDifference--;
    }
    const yearsDifference = Math.floor(monthsDifference / 12);

    // Choose the appropriate time unit based on the magnitude of the difference
    let result;
    if (daysDifference < 31) {
      result = `${daysDifference} days`;
    } else if (monthsDifference < 12) {
      result = `${monthsDifference} months`;
    } else {
      result = `${yearsDifference} years`;
    }

    return result;

    };

  const onSeeMore = () => {
    // show more content
    setSeeMoreContent(data);
    setShowMoreContent(true);
  };
  const onCloseSeeMore = () => {
    // close more content
    setSeeMoreContent();
    setShowMoreContent(false);
  };



    const toggleLike = async (event) => {
        // clicking liked
        event.preventDefault();
        event.stopPropagation();

        if (!user) {
            setShowModal(true);
            return;
        }

        try {
            const likeDocRef = doc(db, "posts", data?.id);
            const newHasLiked = !hasLiked;
            await updateDoc(likeDocRef, {
                [`likes.${user.uid}`]: newHasLiked
            });
            setHasLiked(newHasLiked);
            if (hasDisliked) {
                setHasDisliked(false);
                // Update the dislike in your database as well
                await updateDoc(likeDocRef, {
                    [`dislikes.${user.uid}`]: false
                });
            }
          } catch (error) {
            console.error("Error updating like:", error);
          }
    };

    const toggleDislike = async (event) => {
        // clicking disliked
        event.preventDefault();
        event.stopPropagation();

        if (!user) {
            setShowModal(true);
            return;
        }

        try {
            const dislikeDocRef = doc(db, "posts", data?.id);
            const newHasDisliked = !hasDisliked;
            await updateDoc(dislikeDocRef, {
                [`dislikes.${user.uid}`]: newHasDisliked
            });
            setHasDisliked(newHasDisliked);
            if (hasLiked) {
                setHasLiked(false);
                // Update the like in your database as well
                await updateDoc(dislikeDocRef, {
                    [`likes.${user.uid}`]: false
                });
            }
          } catch (error) {
            console.error("Error updating dislike:", error);
          }

    };
     
    const handleClose = () => {
        setShowModal(false); // Function to close the modal
    };

  return (
    <>
        <div className="Product-Activity-Card">
        <div className="Product-Activity-Card-Profile">
            <i>
            <Link to={`/Creator/${data?.creator?.replace("@", "")}`}>
            <Image
                src={data?.creatorDetails?.profile_pic}
                className="circle-image"
            />
            </Link>
            </i>
            <div>
            <Link to={`/Creator/${data?.creator?.replace("@", "")}`}>
                @{data?.creator}
            </Link>
            <p className="derm-subtitle">
                {data?.creatorDetails?.dermatologist === 1 ? "dermatologist •" : ""}
                <span>{calculateDateDifferences(data?.date)} ago</span>
            </p>
            </div>
        </div>
        {data?.thumbnail !== null && data?.source.startsWith("0") && 
        ( <div>
                <Link to={data?.original_link}><div className="grey-box">
                    <div className="image-from-storage">
                        <img src={data?.thumbnail} alt="thumbnail" className="full-width-image" />
                    </div>
                    <div className="grey-box-content">
                        <div className="blog-title"> {data?.title}</div>
                        <div className="blog-subtitle"> {data?.subtitle}</div>
                    </div>
                </div>
                </Link>
        </div>)}
        {data.show !== 0 && (
            <>
            {isPlaying ? (
                <>
                <Link onClick={togglePlay} to="#" className={videoClassName}>
                    <video controls autoPlay preload>
                    <source src={data?.link} type="video/mp4" />
                    </video>
                </Link>
                </>
            ) : (
                <>
                <Link onClick={togglePlay} to="#" className={videoClassName}>
                    <Image src={data?.thumbnail} />
                    {data?.source === "instagram" ? (
                    <span>
                        <Icon icon="ri:instagram-line" />
                        <div className="source-formatting">instagram.com</div>
                    </span>
                    ) : data?.source === "youtube" ? (
                        <span>
                            <Icon icon="ph:youtube-logo" />
                            <div className="source-formatting">youtube.com</div>
                        </span>
                    ) : data?.source === "0-cosmopolitan" ? (
                        <span>
                            <Icon icon="ph:newspaper-clipping-light" />
                            <div className="source-formatting">cosmopolitan.com</div>
                        </span>
                    ) : data?.source === "0-byrdie" ? (
                        <span>
                            <Icon icon="ph:newspaper-clipping-light" />
                            <div className="source-formatting">byrdie.com</div>
                        </span>
                    ) : (
                        <span>
                            <Icon icon="ph:newspaper-clipping-light" />
                            <div className="source-formatting">healthline.com</div>
                        </span>
                    )}
                    <i>
                    <Icon icon="ion:play" />
                    </i>
                </Link>
                </>
            )}
            </>
        )}
        <div className="Product-Activity-Card-info">
            <ul className="Product-Activity-Tags">
            {data?.recommendation === 1 && (
                <li className="green-tag">
                <i>
                    <Icon icon="ph:check-circle" />
                </i>
                highly recommends
                </li>
            )}
            {data?.recommendation === 3 && (
                <li className="red-tag">
                <i>
                    <Icon icon="ph:warning octagon" />
                </i>
                doesn't recommend
                </li>
            )}
            {data?.skin === 1 ? (
                <li>
                <i>
                <Icon icon="ph:circle-dashed" />
                </i>
                dry skin
                </li>
            ) : data?.skin === 2 ? (
                <li>
                <i>
                <Icon icon="ph:circle-half-tilt" />
                </i>
                oily skin
                </li>
            ) : data?.skin === 3 ? (
                <li>
                <i>
                <Icon icon="material-symbols:circle-outline" />
                </i>
                normal skin
                </li>
            ) : ''}
            {data?.recommendation === 2 && (
                <li className="green-tag">
                <i>
                    <Icon icon="ph:check-circle" />
                </i>{" "}
                recommends
                </li>
            )}
            </ul>
            {!isPostDetails && data?.summary ? <Paragraph
            item={data?.summary}
            classname="truncate-summary mb-0"
            handleShow={onSeeMore}
            /> : 
            <p className="truncate-summary">{data?.summary}</p>
            }
            <p style={{ fontWeight: "bold", textDecoration: "underline" }}>
            {data.ingredients === 1 && "#talksIngredients"}
            {data.ad === 1 && "#talksIngredients"}
            </p>
            {location.pathname === "/" && (
            <Link to={`/ProductDetail/${data?.product}`}>
                <div className="score-card-wrap score-card-bg mb-0">
                <div className="score-card-img">
                    <Image src={data?.productDetails?.colored_photo} />
                </div>
                <div className="score-card-info">
                    <h5 className="truncate-text">{data?.productDetails?.name}</h5>
                    <p>{data?.productDetails?.brand}</p>
                    <h6>${data?.productDetails?.price?.toFixed(2)}</h6>
                </div>
                <div className="score-card-score">
                    <h4>
                    {data?.productDetails?.dotscore.toFixed()}{" "}
                    <span>
                        <Icon icon="mdi:chevron-up-circle-outline" />
                    </span>
                    </h4>
                    <i>dotScore</i>
                </div>
                </div>
            </Link>
            )}
            <ul className="like-action">
            <li>
                <Link
                to="#"
                className={hasLiked ? "fill" : ""}
                onClick={toggleLike}>
                <i>
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="like-rotate" >
                    <path className="strokcolor" d="M0.71875 10.7406C0.894743 10.9401 1.11115 11.0998 1.35361 11.2091C1.59606 11.3185 1.85902 11.3751 2.125 11.375H6.5V12.625C6.5 13.4538 6.82924 14.2487 7.41529 14.8347C8.00134 15.4208 8.7962 15.75 9.625 15.75C9.74111 15.7501 9.85495 15.7178 9.95375 15.6568C10.0526 15.5958 10.1324 15.5085 10.1844 15.4047L13.1359 9.5H16.5C16.8315 9.5 17.1495 9.3683 17.3839 9.13388C17.6183 8.89946 17.75 8.58152 17.75 8.25V1.375C17.75 1.04348 17.6183 0.725537 17.3839 0.491117C17.1495 0.256696 16.8315 0.125 16.5 0.125H3.0625C2.6057 0.124843 2.16455 0.29145 1.8219 0.593537C1.47925 0.895623 1.25866 1.31241 1.20156 1.76562L0.264062 9.26562C0.230833 9.52967 0.254195 9.79776 0.332592 10.0521C0.410989 10.3064 0.542625 10.5411 0.71875 10.7406ZM16.5 8.25H13.375V1.375H16.5V8.25ZM1.50469 9.42188L2.44219 1.92188C2.46122 1.7708 2.53475 1.63187 2.64897 1.53118C2.76319 1.43048 2.91023 1.37495 3.0625 1.375H12.125V8.72734L9.25703 14.4641C8.83196 14.379 8.4495 14.1493 8.17474 13.814C7.89997 13.4786 7.74988 13.0585 7.75 12.625V10.75C7.75 10.5842 7.68415 10.4253 7.56694 10.3081C7.44973 10.1908 7.29076 10.125 7.125 10.125H2.125C2.03631 10.125 1.94863 10.1062 1.86778 10.0697C1.78694 10.0332 1.71478 9.97999 1.65611 9.91348C1.59743 9.84697 1.55358 9.76874 1.52748 9.68398C1.50137 9.59922 1.4936 9.50987 1.50469 9.42188Z" fill="#141414" />
                    <path className="fillcolor" d="M1 9.5L2 11L3 10.5H4.5H7C7.16667 10.6667 7.5 11.1 7.5 11.5V12.5L8 14L9 15L10 14.5L10.5 13L11 11.5L12 10C12.1667 9.66667 12.5 8.9 12.5 8.5V7V5.5V4V2.5V2L12 1H10.5H5.5H3L2 2L1.5 4V6V8L1 9.5Z" fill="#141414" />
                    </svg>
                </i>
                </Link>
                {/* {likes.length} */}
            </li>
            <li>
                <Link
                to="#"
                className={hasDisliked ? "fill" : ""}
                onClick={toggleDislike}>
                <i>
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path className="strokcolor" d="M0.71875 10.7406C0.894743 10.9401 1.11115 11.0998 1.35361 11.2091C1.59606 11.3185 1.85902 11.3751 2.125 11.375H6.5V12.625C6.5 13.4538 6.82924 14.2487 7.41529 14.8347C8.00134 15.4208 8.7962 15.75 9.625 15.75C9.74111 15.7501 9.85495 15.7178 9.95375 15.6568C10.0526 15.5958 10.1324 15.5085 10.1844 15.4047L13.1359 9.5H16.5C16.8315 9.5 17.1495 9.3683 17.3839 9.13388C17.6183 8.89946 17.75 8.58152 17.75 8.25V1.375C17.75 1.04348 17.6183 0.725537 17.3839 0.491117C17.1495 0.256696 16.8315 0.125 16.5 0.125H3.0625C2.6057 0.124843 2.16455 0.29145 1.8219 0.593537C1.47925 0.895623 1.25866 1.31241 1.20156 1.76562L0.264062 9.26562C0.230833 9.52967 0.254195 9.79776 0.332592 10.0521C0.410989 10.3064 0.542625 10.5411 0.71875 10.7406ZM16.5 8.25H13.375V1.375H16.5V8.25ZM1.50469 9.42188L2.44219 1.92188C2.46122 1.7708 2.53475 1.63187 2.64897 1.53118C2.76319 1.43048 2.91023 1.37495 3.0625 1.375H12.125V8.72734L9.25703 14.4641C8.83196 14.379 8.4495 14.1493 8.17474 13.814C7.89997 13.4786 7.74988 13.0585 7.75 12.625V10.75C7.75 10.5842 7.68415 10.4253 7.56694 10.3081C7.44973 10.1908 7.29076 10.125 7.125 10.125H2.125C2.03631 10.125 1.94863 10.1062 1.86778 10.0697C1.78694 10.0332 1.71478 9.97999 1.65611 9.91348C1.59743 9.84697 1.55358 9.76874 1.52748 9.68398C1.50137 9.59922 1.4936 9.50987 1.50469 9.42188Z" fill="#141414" />
                    <path className="fillcolor" d="M1 9.5L2 11L3 10.5H4.5H7C7.16667 10.6667 7.5 11.1 7.5 11.5V12.5L8 14L9 15L10 14.5L10.5 13L11 11.5L12 10C12.1667 9.66667 12.5 8.9 12.5 8.5V7V5.5V4V2.5V2L12 1H10.5H5.5H3L2 2L1.5 4V6V8L1 9.5Z" fill="#141414"/>
                    </svg>
                </i>
                </Link>
                {/* {dislikes.length} */}
            </li>
            </ul>
        </div>
        <Modal show={showMoreContent}>
            <Modal.Body>
            <div className="Gradients-content">
                <div className="Gradients-Title-Icons">
                <Link to="#" onClick={onCloseSeeMore}>
                    <Icon icon="octicon:x-24" />
                </Link>
                </div>
            </div>
            {showMoreContent && seeMoreContent && <ProductActivityCard data={seeMoreContent} isPostDetails={true}/>}
            <div className="Review-Card-Details ReviewsDetailsBox">
            </div>
            </Modal.Body>
        </Modal>
        </div>
        {isPostDetails && <div className="SimilarProducts-Main">
            <SimilarProducts hydration_score={data?.productDetails?.hydration_score}/>
        </div>}
        {/* Modal for Sign-In */}
        {showModal && (
                <Modal show={showModal} className="SignIn-Modal" onHide={handleClose}>
                    <Modal.Body>
                        <div className="Gradients-content">
                            <div className="Gradients-Title-Icons">
                                <Link to="#" onClick={handleClose}>
                                    <Icon icon="octicon:x-24" />
                                </Link>
                            </div>
                        </div>
                        <SignIn handleClose={handleClose} />
                    </Modal.Body>
                </Modal>
            )}
    </>
  );
}

export default ProductActivityCard;
