import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, updateProfile, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./SignIn.css";
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import SignIn from './SignIn';

function SignUp() {
  const auth = getAuth();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [name, setName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [reconfirmedPassword, setReconfirmedPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = (val) => {
    setShow(false)
};
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (password !== reconfirmedPassword) {
      setErrorMessage("Passwords do not match");
      setIsLoading(false);
      return;
    }
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        // Update user profile with the name
        return updateProfile(user, {
          displayName: name,
        });
      })
      .then(() => {
        setSuccessMessage('You have successfully signed up!');
        setErrorMessage('');
        navigate('/');
      })
      .catch((error) => {
        setErrorMessage(`Error: ${error.message}`);
        setSuccessMessage('');
      })
      .finally(() => {
        setIsLoading(false); // Stop loading
      });
  };

  const handleGoogleSignIn = (event) => {
    event.preventDefault(); // Prevent default link behavior
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // Handle sign-in success
        const user = result.user;
        setSuccessMessage('Successfully signed up with Google!');

        setTimeout(() => {
          navigate('/');;
        }, 1000); // Close modal on success
      })
      .catch((error) => {
        // Handle Errors here.
        setErrorMessage(`Google sign-up error: ${error.message}`);
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="signup-container">
    <div>
      <div className='SignIn-Header-Title'>Sign-Up</div>
      <div className='SignIn-SubHeader-Title'>Welcome to shopdot!</div>
    <div>
      <form onSubmit={handleSubmit}>
      <div className='Box-Header'> Email </div>
      <div className="input-container">
          <input 
            className="input-box" 
            type="email" 
            placeholder="Email" 
            onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className='Box-Header'>Name</div>
      <div className="input-container">
        <input 
          className="input-box" 
          type="text" 
          placeholder="Name" 
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className='Box-Header'>Create Password</div>
      <div className="input-container">
          <input 
              className="input-box" 
              type={showPassword ? "text" : "password"} 
              placeholder="Password" 
              onChange={(e) => setPassword(e.target.value)}
          />
          <button type="button" onClick={togglePasswordVisibility}>
              {showPassword ? <Icon icon="ph:eye-slash" style={{ fontSize: '20px' }}/> : <Icon icon="ph:eye" style={{ fontSize: '20px' }}/>}
          </button>
      </div>
      <div className='Box-Header'>Confirm Password</div>
      <div className="input-container">
        <input 
          className="input-box" 
          type={showPassword ? "text" : "password"} 
          placeholder="Password" 
          onChange={(e) => setReconfirmedPassword(e.target.value)}
        />
          <button type="button" onClick={togglePasswordVisibility}>
              {showPassword ? <Icon icon="ph:eye-slash" style={{ fontSize: '20px' }}/> : <Icon icon="ph:eye" style={{ fontSize: '20px' }}/>}
          </button>
      </div>
      <div className="terms-prompt">By creating an account, I agree to the terms of use and the privacy policy.</div>
        <Button type="submit" className="submit-btn">Sign Up</Button>
        {isLoading && <div className="loading"></div>}
      </form>
      <div class="mid-line">
          <div class="line"></div>
          <span class="text">or</span>
          <div class="line"></div>
      </div>
      <div>
        <Link className="signup-btn" onClick={handleGoogleSignIn}> <Icon icon="fa6-solid:g" /> Sign Up with Google</Link>
      </div>
      <div className='forgot-password'> {"Already have an account? "}
      <span className="signup-link" onClick={handleShow}>Sign in</span>
      <Modal show={show} className="SignIn-Modal">
        <Modal.Body>
            <div className="Gradients-content">
            <div className="Gradients-Title-Icons">
                <Link to="#" onClick={() => handleClose()}>
                <Icon icon="octicon:x-24" />
                </Link>
            </div>
            </div>
            <SignIn handleClose={handleClose} />
        </Modal.Body>
        </Modal>
      </div>

    {successMessage && <div className="success-message-signup">{successMessage}</div>}
    {errorMessage && <div className="error-message-signup">{errorMessage}</div>}
    </div>
    </div>
    </div>
  );
}

export default SignUp;