import { Icon } from "@iconify/react";
import "./WhatsSkinType.css";
import React, { useState } from "react";
import { Button } from "react-bootstrap";

function WhatsSkinType({ filter, handleClose, type }) {
  const [filterData, setFilterData] = useState({
    dry: filter.dry,
    oily: filter.oily,
    normal: filter.normal,
    order: filter.order,
    noWhiteCast: filter.noWhiteCast,
    chemical: filter.chemical,
    mineral: filter.mineral,
    goodUnderMakeup: filter.goodUnderMakeup,
    goodForAcne: filter.goodForAcne,
    oilBased: filter.oilBased,
    waterBased: filter.waterBased
  });

  const onHandleSelectSkinType = (e) => {
    const { name, checked } = e.target; // Use `name` instead of `value`
    setFilterData({
      ...filterData,
      [name]: checked, // Toggle based on `checked`, key by `name`
    });
  };

  const onSetOrder = (val) => {
    setFilterData((prev) => {
      return { ...prev, order: val };
    });
  };

  const getCountFilters = () => {
    return Object.values(filterData).filter((val) => val === true).length;
  };

  const onHandlePreferenceChange = (e) => {
    const { name, checked } = e.target;
    setFilterData({
      ...filterData,
      [name]: checked,
    });
  };  

  return (
    <div className="WhatsSkinType-popup">
      <div className="WhatsSkinType-popup-title-case">
        <Icon className="WhatsSkinType-popup-wand"icon="ph:magic-wand"></Icon>
        <div className="WhatsSkinType-placeholder">
        <div className="sort-card-title">personalize your search{" "}
        </div>
        <small>sort & filter to your preference</small>
        </div>
      </div>
      <div className="default-card mb-3">
      <div className="WhatsSkinType-popup-title-type">
        <div className="sort-card-title"> What’s your skin type?{" "}</div>
        <small>we’ll suggest the top-rated moisturizers for you</small>
      </div>
        <div className="skintype-wrap">
          <label htmlFor="Dry-skintype">
            <input  type="checkbox"  name="dry"  id="Dry-skintype" onChange={onHandleSelectSkinType}  checked={filterData.dry}/>
            <div className="skintype-box">
              <span>
                <Icon icon="ph:circle-dashed" />
              </span>
              <p>Dry</p>
            </div>
          </label>
          <label htmlFor="Oily-skintype">
            <input  type="checkbox"  name="oily"  id="Oily-skintype" onChange={onHandleSelectSkinType}  checked={filterData.oily}/>
            <div className="skintype-box">
              <span>
                <Icon icon="ph:circle-half-tilt" />
              </span>
              <p>Oily</p>
            </div>
          </label>
          <label htmlFor="Normal-skintype">
            <input type="checkbox"  name="normal"  id="Normal-skintype" onChange={onHandleSelectSkinType}  checked={filterData.normal}/>
            <div className="skintype-box">
              <span>
                <Icon icon="material-symbols:circle-outline" />
              </span>
              <p>Combination</p>
            </div>
          </label>
        </div>
      </div>
      {type === 'sunscreen' && (
      <div className="default-card-filter">
        <div className="WhatsSkinType-popup-title-type">
        <div className="sort-card-title">sunscreen</div>
        {/* <small>See products top-rated darker skin tones. But what’s white cast?</small> */}
        </div>
        <label htmlFor="noWhiteCast" className="custom-checkbox">
          <input type="checkbox" name="noWhiteCast" id="noWhiteCast" onChange={onHandlePreferenceChange} checked={filterData.noWhiteCast}/>
          <span><Icon icon="ic:round-check" /></span>
          <p>no white cast</p>
        </label>
        <label htmlFor="goodUnderMakeup" className="custom-checkbox">
          <input type="checkbox" name="goodUnderMakeup" id="goodUnderMakeup" onChange={onHandlePreferenceChange} checked={filterData.goodUnderMakeup}/>
          <span><Icon icon="ic:round-check" /></span>
          <p>great under makeup</p>
        </label>
        <label htmlFor="chemical" className="custom-checkbox">
          <input type="checkbox" name="chemical" id="chemical" onChange={onHandlePreferenceChange} checked={filterData.chemical}/>
          <span><Icon icon="ic:round-check" /></span>
          <p>chemical-based sunscreen</p>
        </label>
        <label htmlFor="mineral" className="custom-checkbox">
          <input type="checkbox" name="mineral" id="mineral" onChange={onHandlePreferenceChange} checked={filterData.mineral}/>
          <span><Icon icon="ic:round-check" /></span>
          <p>mineral-based sunscreen</p>
        </label>
      </div>
      )}
      {type === 'cleanser' && (
      <div className="default-card-filter">
        <div className="WhatsSkinType-popup-title-type">
        <div className="sort-card-title">cleanser</div>
        </div>
        <label htmlFor="goodForAcne" className="custom-checkbox">
          <input type="checkbox" name="goodForAcne" id="goodForAcne" onChange={onHandlePreferenceChange} checked={filterData.goodForAcne}/>
          <span><Icon icon="ic:round-check" /></span>
          <p>great for acne</p>
        </label>
        <label htmlFor="oilBased" className="custom-checkbox">
          <input type="checkbox" name="oilBased" id="oilBased" onChange={onHandlePreferenceChange} checked={filterData.oilBased}/>
          <span><Icon icon="ic:round-check" /></span>
          <p>oil-based cleanser</p>
        </label>
        <label htmlFor="waterBased" className="custom-checkbox">
          <input type="checkbox" name="waterBased" id="waterBased" onChange={onHandlePreferenceChange} checked={filterData.waterBased}/>
          <span><Icon icon="ic:round-check" /></span>
          <p>water-based cleanser</p>
        </label>
      </div> )}
      <div className="default-card-filter">
      <div className="WhatsSkinType-popup-title-type">
      <div className="sort-card-title">sort by</div>
      </div>
        <label htmlFor="dotscore" className="custom-radio">
          <input type="radio"  name="sortby"  id="dotscore"  checked={filterData.order === "dotscore"}  onClick={() => onSetOrder("dotscore")}/>
          <span></span>
          <p>dotScore</p>
        </label>
        <label htmlFor="price" className="custom-radio">
          <input type="radio"  name="sortby" id="price"  checked={filterData.order === "price"}  onClick={() => onSetOrder("price")} />
          <span></span>
          <p>price</p>
        </label>
      </div>
      <Button variant=""  className="btn-black btn-apply" onClick={() => handleClose(filterData, getCountFilters(filterData))} >
        {getCountFilters(filterData) === 0 ? "Close" : "Apply " + getCountFilters(filterData) + " " +
        (getCountFilters(filterData) === 1 ? "Filter" : "Filters")}
      </Button>
    </div>
  );
}

export default WhatsSkinType;
