import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icon, getIcon } from "@iconify/react";
import { Button, Container, Image, Modal, Nav, Tab } from "react-bootstrap";
import ProductActivity from "../../component/ProductActivity/ProductActivity";
import ReviewsDetails from "../../component/ReviewsDetails/ReviewsDetails";
import Gradients from "../Gradients/Gradients";
import "./ReviewDetail2.css";
import { collection, doc, documentId, getDoc, getDocs, limit, query, where } from "firebase/firestore";
import { db } from "../../utils/firebase-config";
import { useQuery } from "react-query";
import Paragraph from "../../component/Paragraph/Paragraph";
import FilterReviews from "../FilterReviews/FilterReviews";
import { getIconHighlight, getIconConcern } from '../../utils/iconUtils';

const fetchFeatureByProduct = async (productId, featureId, reviewLimit) => {
  const productDocRef = doc(db, "products", productId);

  const [productDoc, featuresSnapshot] = await Promise.all([
    getDoc(productDocRef),
    getDoc(query(doc(productDocRef, "feature", featureId))),
  ]);

  const productData = productDoc.data();
  const featureData = {
    ...featuresSnapshot.data(),
    featureId: featuresSnapshot.id,
  };

  // console.log({ releventReviews123: featureData.relevant_reviews})

  // const releventReviews = [...featureData.relevant_reviews].splice(reviewLimit, 4).map((review) => review.toString())
  // console.log({ releventReviews})



  // const allReviews = await getDocs(
  //   query(
  //     collection(productDocRef, "reviews"),
  //     where(documentId(), "in", releventReviews),
  //     limit(4)
  //   )
  // )

  // const reviews =  allReviews.docs.map((doc) => {
  //   return {...doc.data(), reviewId: doc.id}
  // });

  // console.log({ reviews, featureData: featureData, releventReviews })

  // // let count = 1;
  // // for (const review of featureData.relevant_reviews) {
  // //   const reviewDocRef = doc(productDocRef, "reviews", `${review}`);
  // //   const reviewSnapshot = await getDoc(query(reviewDocRef));
  // //   if (reviewSnapshot.data()) {
  // //     reviews.push({ ...reviewSnapshot.data() });
  // //     if (count === reviewLimit) break;
  // //     count++;
  // //   }
  // // }

  return {
    ...productData,
    feature: featureData,
  };
};

const reviewByFeature = async (productId, feature, reviewLimit, filterData) => {
  const relevantReviews = [...feature?.feature?.relevant_reviews] || [];
  const productDocRef = doc(db, "products", productId);
  // const releventReviews = relevantReviews?.splice(reviewLimit, 4).map((review) => review.toString());
  const relevantReviewIds = relevantReviews.slice(reviewLimit, reviewLimit + 4).map(String);

  let reviewsQuery = query(
    collection(productDocRef, "reviews"),
    where(documentId(), "in", relevantReviewIds),
    where("non_duplicate", "==", true),
  );

  if (filterData.oily) {
    reviewsQuery = query(reviewsQuery, where("oily_label", "==", true));
  } else if (filterData.dry) {
    reviewsQuery = query(reviewsQuery, where("dry_label", "==", true));
  } else if (filterData.normal) {
    reviewsQuery = query(reviewsQuery, where("combo_label", "==", true));
  }
  
  if (filterData.oily || filterData.dry || filterData.normal) {
    reviewsQuery = query(reviewsQuery, limit(200));
  }
  else {
    reviewsQuery = query(reviewsQuery, limit(4));
  }

  const allReviews = await getDocs(reviewsQuery);

  const reviews = allReviews.docs.map((doc) => {
    return { ...doc.data(), reviewId: doc.id }
  });

  return reviews;
}

function getScoreClassName(score) {
  if (score > 80) {
    return "score-card-score green-score-card";
  } else if (score > 60) {
    return "score-card-score yellow-score-card";
  } else {
    return "score-card-score yellow-score-card red-score-card";
  }
}



function ReviewDetail2() {
  const { productId, featureId } = useParams();
  const [reviewLimit, setReviewLimit] = useState(0);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const {
    data: feature,
    isLoading,
    isError,
  } = useQuery(["feature", productId, featureId], () =>
    fetchFeatureByProduct(productId, featureId)
  );
  const [filterData, setFilterData] = useState({});

  const {
    data: reviews,
    isReviewLoading,
    isReviewError,
  } = useQuery(["reviews", productId, feature, reviewLimit, filterData], () =>
    reviewByFeature(productId, feature, reviewLimit, filterData)
  );

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showMoreContent, setShowMoreContent] = useState(false);
  const [seeMoreContent, setSeeMoreContent] = useState("");
  const [reviewList, setReviewList] = useState([]);
  const [searchShow, setSearchShow] = useState(false);
  const handleSearchClose = () => {
    setSearchShow(false)
  };
  const handleSearchShow = () => setSearchShow(true);

  useEffect(() => {
    if (reviews) {
      // Filter out reviews that are already in the reviewList
      const newReviews = reviews.filter(
        (review) =>
          !reviewList.some(
            (existingReview) => existingReview.reviewId === review.reviewId
          )
      );
      // Add only the new reviews to the reviewList
      if (newReviews.length > 0) {
        setReviewList((prev) => [...prev, ...newReviews]);
      }
    }
  }, [reviews, reviewList]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const onSeeMore = (val) => {
    setSeeMoreContent(val);
    setShowMoreContent(true);
  };
  const onCloseSeeMore = () => {
    setSeeMoreContent("");
    setShowMoreContent(false);
  };

  const type = feature?.feature?.feature_type;

  const handleFilterApply = (newFilterData) => {
    setReviewList([]);
    setFilterData(newFilterData); // Update the filterData state
    setSearchShow(false); // Close the filter modal
  };

  let icon_feature = '';

  if (type === 'highlight') {
    icon_feature = getIconHighlight(feature?.feature?.title);
  } else if (type === 'concern') {
    icon_feature = getIconConcern(feature?.feature?.title);
  }

  return (
    <>
      {isLoading ? <div className="loading"></div> : 
      <div className="review-content">
        <Container className="Review-Box">
          <div className={`Review-Box ${isDesktop ? "" : "default-card"}`}>
            <Link to="#" className="back-btn back-btn-reletive" onClick={() => navigate(-1)}>
              <Icon icon="ph:arrow-left" />
            </Link>
            <div className="Review-Box-Title">
            {isDesktop && <div className={`score-card-score ${type === 'highlight' ? 'green-score-card' : type === 'concern' ? 'yellow-score-card' : ''}`}>
                <span>
                  <Icon icon={icon_feature} />
                </span>
              </div>}
              <div className="Review-Box-Info">
                <h5>{feature?.feature?.title}</h5>
                <h6>
                  <span>
                    based on {feature?.feature?.relevant_reviews?.length} reviews
                  </span>
                </h6>
              </div>
              {!isDesktop && <div className={`score-card-score ${type === 'highlight' ? 'green-score-card' : type === 'concern' ? 'yellow-score-card' : ''}`}>
                <span>
                  <Icon icon={icon_feature} />
                </span>
              </div>}
            </div>
            <p className="review-box-info" style={{maxWidth: '838px', marginBottom: '8px'}}>{feature?.feature?.summary}</p>
            {/* <ul>
            <li>
              {feature?.feature?.summary}
              {feature?.feature?.summary} <Link to="#">See More</Link>
            </li>
          </ul> */}
            <Link to={`/ProductDetail/${productId}`} className="score-card-wrap score-card-bg mb-3" style={{ maxWidth: '358px' }}>
              <div className="score-card-img">
                <Image src={feature?.colored_photo} />
              </div>
              <div className="score-card-info">
                <h5 className="truncate-text">{feature?.name}</h5>
                <p>
                  {feature?.brand}
                </p>
                <h6>${feature?.price.toFixed(2)}</h6>
              </div>
              <div className="score-card-score" style={{borderColor: 'white'}}>
              <h4 style={{color: 'black'}}>
                {feature?.dotscore.toFixed()}{" "}
                <span style={{color: 'black'}}>
                  <Icon icon="mdi:chevron-up-circle-outline" />
                </span>
              </h4>
              <i style={{color: 'black'}}>dotScore</i>
            </div>
            </Link>
            {/* <Link
              to="#"
              onClick={handleShow}
              className="btn btn-gray-border btn-Gradients w-100"
            >
              Which ingredients effect this?{" "}
              <i>
                <Icon icon="formkit:arrowright" />
              </i>
            </Link> */}
          </div>
        </Container>
        <div className="reviews-content-info">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <div className="produts-tabs-wrap">
            <Nav variant="pills" className="produts-tabs">
              <Nav.Item>
                <Nav.Link eventKey="first">Reviews</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Activity</Nav.Link>
              </Nav.Item>
            </Nav>
            {/* <Link to="#" className="produts-tabs-cart">
                        <span><Icon icon="fa6-solid:plus" /></span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.875 5H13.75C13.75 4.00544 13.3549 3.05161 12.6517 2.34835C11.9484 1.64509 10.9946 1.25 10 1.25C9.00544 1.25 8.05161 1.64509 7.34835 2.34835C6.64509 3.05161 6.25 4.00544 6.25 5H3.125C2.79348 5 2.47554 5.1317 2.24112 5.36612C2.0067 5.60054 1.875 5.91848 1.875 6.25V15.625C1.875 15.9565 2.0067 16.2745 2.24112 16.5089C2.47554 16.7433 2.79348 16.875 3.125 16.875H16.875C17.2065 16.875 17.5245 16.7433 17.7589 16.5089C17.9933 16.2745 18.125 15.9565 18.125 15.625V6.25C18.125 5.91848 17.9933 5.60054 17.7589 5.36612C17.5245 5.1317 17.2065 5 16.875 5ZM10 2.5C10.663 2.5 11.2989 2.76339 11.7678 3.23223C12.2366 3.70107 12.5 4.33696 12.5 5H7.5C7.5 4.33696 7.76339 3.70107 8.23223 3.23223C8.70107 2.76339 9.33696 2.5 10 2.5ZM16.875 15.625H3.125V6.25H6.25V7.5C6.25 7.66576 6.31585 7.82473 6.43306 7.94194C6.55027 8.05915 6.70924 8.125 6.875 8.125C7.04076 8.125 7.19973 8.05915 7.31694 7.94194C7.43415 7.82473 7.5 7.66576 7.5 7.5V6.25H12.5V7.5C12.5 7.66576 12.5658 7.82473 12.6831 7.94194C12.8003 8.05915 12.9592 8.125 13.125 8.125C13.2908 8.125 13.4497 8.05915 13.5669 7.94194C13.6842 7.82473 13.75 7.66576 13.75 7.5V6.25H16.875V15.625Z" fill="#1F1F1F" />
                        </svg>
                    </Link> */}
          </div>
          <Container>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                {isReviewLoading ? <div className="loading"></div> : (
                  <ReviewsDetails
                    data={reviewList}
                    setReviewLimit={setReviewLimit}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <ProductActivity productId={productId} />
              </Tab.Pane>
            </Tab.Content>
          </Container>
        </Tab.Container> 
        </div>
        <Button variant="" className="btn btn-black btn-skin" onClick={handleSearchShow}>
          <i><Icon icon="ph:magic-wand" /></i> Sort Reviews
        </Button>
        <Modal show={searchShow} className="WhatsSkinType-Modal">
          <Modal.Body>
            <div className="Gradients-content">
              <div className="Gradients-Title-Icons">
                <Link to="#" onClick={() => handleSearchClose()}>
                  <Icon icon="octicon:x-24" />
                </Link>
              </div>
            </div>
            <FilterReviews filter={filterData} handleClose={handleFilterApply}/>
          </Modal.Body>
        </Modal>
        <Modal show={show}>
          <Modal.Body>
            <Gradients handleClose={handleClose} />
          </Modal.Body>
        </Modal>
        <Modal show={showMoreContent}>
          <Modal.Body>
            <div className="Gradients-content">
              <div className="Gradients-Title-Icons">
                <Link to="#" onClick={onCloseSeeMore}>
                  <Icon icon="octicon:x-24" />
                </Link>
                <Link to="#" className="share-icon">
                  <Icon icon="solar:share-linear" />
                </Link>
              </div>
            </div>
            <div className="Review-Card-Details ReviewsDetailsBox">
              <h5>Jessica</h5>
              <h6>
                verified review • <span>2m ago</span>
              </h6>
              <p>
                {seeMoreContent} <strong>#foroilyskin</strong>
              </p>
            </div>
          </Modal.Body>
        </Modal>
      </div>}
    </>
  );
}

export default ReviewDetail2;
