import { Icon } from '@iconify/react'
import React, { useContext, useState } from 'react'
import { Accordion, Container, Image } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import productExample from '../../images/ProductExample.jpg'
import './ComparePage.css'
import PriceHighEnd from '../../component/PriceHighEnd/PriceHighEnd'
import SimilarProducts from '../../component/SimilarProducts/SimilarProducts'
import Footer from '../../component/Footer/Footer'
import DotScore from '../../component/DotScore/DotScore'

export default function ComparePage() {

    const [firstActiveKey, setFirstActiveKey] = useState(0)
    const [secondActiveKey, setSecondActiveKey] = useState(0)
    const [skinType, setSkinType] = useState(1);

    const onFirstAccordionclick = (val) => {
        setFirstActiveKey(val)
    }


    const onSecondAccordionClick = (val) => {
        setSecondActiveKey(val)
    }



    const navigate = useNavigate()
    return (
        <div className='flex flex-col gap-[16px] '>
            <div className='compare-page__main-half'>


                {/* Image Swaps */}
                <div className='compare-page__prod-container'>
                    <Link
                        to="#"
                        onClick={() => navigate(-1)}
                        className="back-btn back-btn-reletive"
                    >
                        <Icon icon="ph:arrow-left" />
                    </Link>

                    <div className='relative h-[400px]'>
                        <div className='absolute top-0 left-0 w-52 rounded-xl overflow-hidden'>
                            <img src={productExample} />
                        </div>

                        <div className='absolute right-0 bottom-0 border-dashed border-4 border-black rounded-xl w-52 mb-8 font-bold'>
                            <img src={productExample} />
                            <div className='absolute right-4 bottom-4 bg-black text-white rounded-lg w-28 px-3 py-2 flex items-center justify-around'>
                                <Icon icon="ph:arrow-left" />
                                Swap
                            </div>
                        </div>
                    </div>

                    <div className="score-card-info w-2/3">
                        <h5>Umbra Tinte Daily SPF 50 vs. Broad Specturm SPF 50</h5>
                        <p>compare sunscreens</p>
                    </div>
                </div>



                {/* Search Bar */}
                <Link className="input-box-container w-full">
                    <div class="thumb-btn thumbs-down"><Icon icon="solar:chat-round-dots-outline" /></div>
                    <input type="text" className="feedback-input" placeholder="tell us what you think..."></input>
                    <div class="thumb-btn thumbs-up"><Icon icon="ph:thumbs-up" /></div>
                    <div class="thumb-btn thumbs-down"><Icon icon="ph:thumbs-down" /></div>
                </Link>

                <div className='w-full flex flex-col gap-4'>
                    <div>
                        <h5 className='font-bold text-lg'>head-to-head</h5>
                        <span><span className='font-semibold text-sm'>overall rating</span>  • based on 1,234 reviews</span>
                    </div>

                    <p className='text-sm'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                </div>


                <Accordion className="Review-Accordion !mb-0 w-full">
                    <DotScoreAccItem itemKey={0} title={"Umbra Tinte Daily SPF 50"} onAccordionclick={onFirstAccordionclick} score={92} activeKey={firstActiveKey} />
                    <DotScoreAccItem itemKey={1} title={"Broad Specturm SPF 50"} onAccordionclick={onFirstAccordionclick} score={92} activeKey={firstActiveKey} />
                </Accordion>


            </div >



            <div className='compare-page__main-half2'>
                <div>
                    <h5 className='font-bold text-lg'>The one for you?</h5>
                    <span className='text-sm'>Compare sunscreens by skin type & preference to pick the right one for you.</span>
                </div>

                <div className='compare-page__one-for-you-container'>
                    <h5 className='font-bold text-lg'>What's your skin type?</h5>

                    <div className='flex justify-center gap-12 text-4xl mt-2' >
                        <SkinTypeButton 
                            icon={"fluent:circle-hint-16-regular"}
                            title={"dry"}
                            active={skinType === 0}
                            onClick={() => {setSkinType(0)}}
                         />
                        <SkinTypeButton 
                            icon={"ph:circle-half"} 
                            title={"oily"}
                            active={skinType === 1}
                            onClick={() => {setSkinType(1)}}                        
                        />
                        <SkinTypeButton 
                            icon={"iconoir:circle"}
                            title={"combo"}
                            active={skinType === 2}
                            onClick={() => {setSkinType(2)}}
                        />
                    </div>

                    

                    <div className='flex flex-col gap-2'>
                    <div className='flex gap-4 border-b-[1px] py-3 justify-between items-center'>
                        <div>
                            <h5 className='font-bold'>Umbra Tinte Daily SPF 50</h5>
                            <h6 className='text-xs'>
                                <b className={getScoreClassText(96).className + (getScoreClassText(96).className ? " has-dot" : "")}>{getScoreClassText(96).text}</b> based on{" "}
                                1,234 reviews
                            </h6>
                        </div>
                        <DotScore dotscore={96} grey={false} />

                    </div>


                    <div className='flex gap-4 border-b-[1px] pb-3 justify-between items-center'>
                        <div>
                            <h5 className='font-bold'> Broad Specturm SPF 50</h5>
                            <h6 className='text-xs'>
                                <b className={getScoreClassText(96).className + (getScoreClassText(96).className ? " has-dot" : "")}>{getScoreClassText(96).text}</b> based on{" "}
                                1,234 reviews
                            </h6>
                        </div>
                        <DotScore dotscore={96} grey={false} />

                    </div>



                    <p className='text-sm'> dotScores here suggest moisturizers best suited for your skin type based on self-reported skin types. <Link to="/scoreexplained" className='dotScore-link'>What’s a dotScore?</Link> </p>
                    </div>
                </div>

                <Accordion className="Review-Accordion !mb-0 w-full">
                    <CategoryAccItem itemKey={0} title={"white cast"} onAccordionclick={onSecondAccordionClick} activeKey={secondActiveKey} />
                    <CategoryAccItem itemKey={2} title={"hydration"} onAccordionclick={onSecondAccordionClick} activeKey={secondActiveKey} />
                    <CategoryAccItem itemKey={3} title={"acne"} onAccordionclick={onSecondAccordionClick} activeKey={secondActiveKey} />
                    <CategoryAccItem itemKey={4} title={"texture"} onAccordionclick={onSecondAccordionClick} activeKey={secondActiveKey} />
                    <CategoryAccItem itemKey={5} title={"smell"} onAccordionclick={onSecondAccordionClick} secondActiveKey activeKey={secondActiveKey} />
                </Accordion>
            </div>



            <div className='compare-page__main-half3'>

                <div className="PriceHighEnd-Main">
                    {/* <PriceHighEnd /> */}
                </div>

                <div className="SimilarProducts-Main z-50 mb-20">
                    {/* <h3>similar products</h3> */}
                    <SimilarProducts hydration_score={94} />
                </div>

            </div>
            <Footer/>



        </div>


    )
}

function SkinTypeButton({icon, title, active, onClick}){

    var cName = !active ? " text-gray-500 " : " text-black border-b-[1px] border-black py-2"

    return (
        <div 
            className={"flex flex-col cursor-pointer items-center justify-center " + cName}
            onClick={onClick}
        >
            <Icon icon={icon} className='text-4xl'/>
            <span className='!text-sm'>{title}</span>
        </div>
    )

}

function getScoreClassText({score}) {
    if (score > 90) {
        return { className: "green-text", text: 'top-rated' };
    } else if (score < 63) {
        return { className: '"yellow-text"', text: 'below average' };
    } else {
        return { className: '', text: '' };
    }
}

function DotScoreAccItem({ itemKey, title, product, onAccordionclick, score, desc, activeKey }) {

    return (
        <Accordion.Item eventKey={itemKey} key={itemKey} onClick={() => onAccordionclick(itemKey)}>
            <Accordion.Header>
                <div className="ReviewAccordionWrap">
                    <DotScore dotscore={parseInt(score)} grey={true} />
                    <div>
                        <h5>{title}</h5>
                        <h6>
                            <b className={getScoreClassText(score).className + (getScoreClassText(score).className ? " has-dot" : "")}>{getScoreClassText(score).text}</b> based on{" "}
                            1,234 reviews
                        </h6>
                    </div>
                </div>
            </Accordion.Header>


            {activeKey === itemKey &&

                <Accordion.Body >
                    <div className="w-full flex h-full ">
                        <div className="flex flex-col justify-between text-md ">

                            <p>
                                {desc}
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </p>


                            <div className='w-full flex gap-2 mt-2'>
                                <div className={"basicPill"}>
                                    <span><Icon icon="ph:arrow-right" /></span>
                                    <span>has zinc</span>
                                </div>
                                <div className={"basicPill"}>
                                    <span><Icon icon="ph:arrow-right" /></span>
                                    <span>has zinc</span>
                                </div>
                            </div>

                        </div>

                    </div>
                </Accordion.Body>
            }
        </Accordion.Item>

    )
}


function CategoryAccItem({ itemKey, title, product1, product2, onAccordionclick, desc, activeKey }) {



    return (
        <Accordion.Item eventKey={itemKey} key={itemKey} onClick={() => onAccordionclick(itemKey)}>
            <Accordion.Header>
                <div className="ReviewAccordionWrap">
                    <h5>{title}</h5>

                </div>
            </Accordion.Header>


            {activeKey === itemKey &&

                <Accordion.Body >
                    <div className="w-full flex flex-col gap-4 h-full ">


                        <div className='flex gap-4 border-b-[1px] py-3 justify-between items-center'>
                            <div>
                            <h5 className='font-bold'>Umbra Tinte Daily SPF 50</h5>
                            <h6 className='text-xs'>
                                <b className={getScoreClassText(96).className + (getScoreClassText(96).className ? " has-dot" : "")}>{getScoreClassText(96).text}</b> based on{" "}
                                1,234 reviews
                            </h6>
                            </div>
                            <DotScore dotscore={96} grey={false} />

                        </div>


                        <div className='flex gap-4 border-b-[1px] pb-3 justify-between items-center'>
                            <div>
                            <h5 className='font-bold'>Broad Spectrum SPF 50</h5>
                            <h6 className='text-xs'>
                                <b className={getScoreClassText(96).className + (getScoreClassText(96).className ? " has-dot" : "")}>{getScoreClassText(96).text}</b> based on{" "}
                                1,234 reviews
                            </h6>
                            </div>
                            <DotScore dotscore={96} grey={false} />

                        </div>


                        <p className='text-sm'>
                            {desc}
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </p>

                    </div>
                </Accordion.Body>
            }
        </Accordion.Item>

    )
}



