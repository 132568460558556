import { Icon } from "@iconify/react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Paragraph from "../Paragraph/Paragraph";
import "./ReviewsDetailsBox.css";

function recommend_level(input) {
  if (input === 1 || input === 2) {
    return <><i><Icon icon="ph:x-circle" /></i> doesn’t recommend</>;
  }
  if (input === 3) {
    return <><i><Icon icon="ph:check-circle-light" /></i>  recommends</>;
  }
  if (input === 4 || input === 5) {
    return <><i><Icon icon="ph:check-circle-light" /></i>  highly recommends</>;
  }
}
function recommend_level_class(input) {
  if (input === 1 || input === 2) {
    return 'red-box';
  }
  if (input === 3) {
    return "green-box";
  }
  if (input === 4 || input === 5) {
    return 'green-box';
  }
}

function name_checker(input, source) {
  if (input === "") {
    return source.split(".com")[0] + " customer";
  }
  return input;
}

function skin_type(oily, oily_confidence, dry, dry_confidence, combo, combo_confidence) {
  let skinTypes = [];

  if (oily === true && oily_confidence > 0.9) {
    skinTypes.push(<><i><Icon icon="ph:circle-half-tilt" /></i> oily skin</>);
  }
  if (dry === true && dry_confidence > 0.9) {
    skinTypes.push(<><i><Icon icon="ph:circle-dashed" /></i> dry skin</>);
  }
  if (combo === true && combo_confidence > 0.9) {
    skinTypes.push(<><i><Icon icon="material-symbols:circle-outline" /></i> normal skin</>);
  }

  // Return a JSX fragment containing all matched skin types
  return skinTypes.length > 0 ? (
    <>{skinTypes.reduce((prev, curr) => [prev, ' ', curr])}</>
  ) : null;
}

function ReviewsDetailsBox({ data }) {
  
  const [show, setShow] = useState(false);
  const [reviewDetail, setReviewDetail] = useState('');

  const handleClose = () => {
    setReviewDetail('');
    setShow(false);
  };

  const handleShow = (data) => {
    setReviewDetail(data);
    setShow(true);
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "";

    // Firestore Timestamp to JavaScript Date
    const date = new Date(timestamp.seconds * 1000);

    // Format the date as you prefer
    return date.toLocaleDateString("en-US"); // or any other format
  };

  const skinTypeResult = skin_type(data?.oily_label, data?.oily_confidence, data?.dry_label, data?.dry_confidence, data?.combo_label, data?.combo_confidence);
  return (
    <div className="default-card ReviewsDetailsBox">
      <h5> {name_checker(data?.name, data?.source)}</h5>
      <h6>
         <span> from {data?.source}• {formatDate(data?.date)}</span>
      </h6>
      <div className="tags-container">
        <ul className="Product-Activity-Tags">
            <li className={recommend_level_class(data?.stars)}>
                {recommend_level(data?.stars)}
            </li>
        </ul>
        {skinTypeResult && <ul className="Product-Activity-Tags">
            <li className="grey-box-1">
                {skinTypeResult}
            </li>
        </ul>}
      </div>
      <Paragraph item={data?.body} handleShow={handleShow} />
      <Modal show={show}>
        <Modal.Body>
          <div className="Gradients-content">
            <div className="Gradients-Title-Icons">
              <Link to="#" onClick={handleClose}>
                <Icon icon="octicon:x-24" />
              </Link>
              <Link to="#" className="share-icon">
                <Icon icon="solar:share-linear" />
              </Link>
            </div>
          </div>
          <div className="Review-Card-Details ReviewsDetailsBox" style={{width: '100%'}}>
            <h5>{name_checker(data?.name, data?.source)}</h5>
            <h6>
              <span> from {data?.source}• {formatDate(data?.date)}</span>
            </h6>
            <div className="tags-container">
            <ul className="Product-Activity-Tags">
                <li className={recommend_level_class(data?.stars)}>
                    {recommend_level(data?.stars)}
                </li>
            </ul>
            {skinTypeResult && <ul className="Product-Activity-Tags">
                <li className="grey-box-1">
                    {skinTypeResult}
                </li>
            </ul>}
      </div>
            <p>
              {reviewDetail}
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ReviewsDetailsBox;
