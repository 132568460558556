import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Nav, Tab, Modal, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import Moisturizers from "../../component/Moisturizers/Moisturizers";
import ProductActivity from "../../component/ProductActivity/ProductActivity";
import { Image } from "react-bootstrap";
import './ProfilePage.css'

function ProfilePage() {
    const navigate = useNavigate();
    const auth = getAuth();
    const [user, setUser] = useState(null);
    const [showSignOutModal, setShowSignOutModal] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [auth]);

    const userName = user ? user.displayName || 'User' : 'Guest';

    const handleSignOutConfirm = () => {
        setShowSignOutModal(false);
        signOut(auth).then(() => {
            navigate('/');
        }).catch((error) => {
            console.error("Sign out error", error);
        });
    };

    return (
        <div className="main-content">
            <Container>
                <div className="profile-head">
                    <Link to="#" onClick={() => navigate(-1)}><Icon icon="ph:arrow-left" /></Link>
                    <Link to="#" className="btn-logout" onClick={() => setShowSignOutModal(true)}>
                        <Icon icon="ph:sign-out-bold" />
                    </Link>
                    <Modal show={showSignOutModal} onHide={() => setShowSignOutModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Sign Out</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to sign out?
                            <div style={{ marginTop: '50px' }}>
                                <div className="button-container">
                                    <Button className="cancel-btn" variant="secondary" onClick={() => setShowSignOutModal(false)} style={{ backgroundColor: "black" }}> Cancel </Button>
                                </div>
                                <div className="button-container">
                                    <Button className="signout-btn" variant="primary" onClick={handleSignOutConfirm}>
                                        Sign Out
                                    </Button>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                    </Modal>
                </div>
                <div className="profile-info">
                    <img className="profile-photo" style={{ marginBottom: '12px' }} src="https://firebasestorage.googleapis.com/v0/b/shopdot-b9a58.appspot.com/o/profile_pics%2Fpineapple.jpeg?alt=media&token=53d5dc9d-d6be-436d-9faa-e91f0aa271a1" alt="profile" />
                    <h6><span>welcome back,</span> {userName} </h6>
                </div>
                <div className="button-wrapper">
                    <Link to="/ManageRoutine"className="follow-button">
                        Manage routine or products
                    </Link>
                    {/* <div className="">
                        See liked products
                    </div> */}
                </div>
            </Container>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="produts-tabs-wrap border-0">
                    <Nav variant="pills" className="produts-tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Favorites</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Activity</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <Container>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <Moisturizers MoisturizersTitle="favorites" user={user} favoritesOnly={true} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            {/* <ProductActivity /> */}
                        </Tab.Pane>
                    </Tab.Content>
                </Container>
            </Tab.Container>
        </div>
    );
}

export default ProfilePage;
