import React from 'react';
import './OrderPlaced.css'; // Import the CSS file for styling
import { Icon } from '@iconify/react';
import  { Link } from 'react-router-dom';

const OrderPlaced = () => {

  return (
    <div className="order-placed-container">
      <div className="order-placed-content">
        <Icon icon="cil:check-alt" className="check-icon"/>
        <h2>Order placed!</h2>
        <div className="bundle-for-you" style={{ marginTop: '12px', marginLeft: '24px', padding: '0px' }}>
          <h3 style={{fontSize: '24px'}}>Your <span style={{fontSize: '24px'}} className="bundle-for-you-header-special">order</span> is getting ready!</h3>
        </div>
        <Link to="/Profile" className="manage-button">
          Manage
        </Link>
      </div>
    </div>
  );
};

export default OrderPlaced;
