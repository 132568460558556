import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image } from "react-bootstrap";
import './ProductCard.css'
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore"; 
import { db } from "../../utils/firebase-config";
import SignIn from "../../Pages/SignIn/SignIn";
import { Modal } from "react-bootstrap";

function getScoreClassIcon(score) {
    if (score > 95) {
        return { className: 'green-box', icon: 'ph:trophy' };
    } else if (score < 45) {
        return { className: 'yellow-box', icon: 'ph:toilet-paper' };
    } else {
        return '';
    }
}

function SampleNextArrow(props) {
    // next button for slider
    const { className, style, onClick } = props;
    return (
        <>
        <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={onClick}>
        </div>
        </>
    );
  }
  
  function SamplePrevArrow(props) {
    // previous button for slider
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={onClick}
      />
    );
  }
  

  function getScoreClassText(dry_score, oily_score, combo_score) {
    const skinTypes = [
        { type: 'dry', score: dry_score },
        { type: 'oily', score: oily_score },
        { type: 'normal', score: combo_score }
    ];

    const highRated = skinTypes.filter(skin => skin.score > 89);
    const lowRated = skinTypes.filter(skin => skin.score < 63);

    if (highRated.length === 3) {
        return { className: 'Rated-tag green-text', text: 'top-rated for all skin types' };
    } else if (lowRated.length === 3) {
        return { className: 'Rated-tag yellow-text', text: 'below average for all' };
    } else if (highRated.length > 0) {
        const skinTypesText = highRated.map(skin => skin.type).join(' & ');
        return { className: 'Rated-tag green-text', text: `top-rated for ${skinTypesText} skin` };
    } else if (lowRated.length > 0) {
        const skinTypesText = lowRated.map(skin => skin.type).join(' & ');
        return { className: 'Rated-tag yellow-text', text: `below average for ${skinTypesText} skin` };
    }

    return { className: '', text: '' };
}


const ProductCard = ({ data, user }) => {
    const [isFavorite, setIsFavorite] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const settings = {
        dots: true,
        arrows: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const score = data?.dotscore?.toFixed();
    const oily_score = data?.oily_score?.toFixed();
    const dry_score = data?.dry_score?.toFixed();
    const combo_score = data?.combo_score?.toFixed();

    const scoreClassText = getScoreClassText(dry_score, oily_score, combo_score);

    const scoreClassIcon = getScoreClassIcon(score);
    const formatReviewCount = (count) => {
        const formattedCount = count / 1000;
        return formattedCount % 1 === 0 ? formattedCount.toFixed(0) + 'k' : formattedCount.toFixed(1).replace('.0', '') + 'k';
    };
    
    let highestScore = Math.max(data?.dry_score, data?.oily_score, data?.combo_score);
    // console.log(data)
    let hashtag;
    if (highestScore === data?.dry_score) {
    hashtag = "#fordryskin";
    } else if (highestScore === data?.oily_score) {
    hashtag = "#foroilyskin";
    } else if (highestScore === data?.combo_score) {
    hashtag = "#fornormalskin";
    } else {
    hashtag = ""; // or some default value
    }

    useEffect(() => {
    // checking if a user has or hasn't liked a product
        const checkIfFavorited = async () => {
            if (user && data?.productId) {  // Check if both user & data.productId are defined
                const favoriteDocRef = doc(db, "favorite_products", data.productId);
                const favoriteDoc = await getDoc(favoriteDocRef);

                if (favoriteDoc.exists()) {
                    const favoriteData = favoriteDoc.data();
                    setIsFavorite(favoriteData.user_ids && favoriteData.user_ids[user?.uid]);
                }
            }
        };
        if (data?.productId) { // Only call checkIfFavorited if data.productId is defined
            checkIfFavorited();
        }
    }, [user, data.productId]);

    
    // Function to handle favorite click
    const toggleFavorite = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        console.log(data?.productId)
        if (!user) {
            setShowModal(true);
            return;
        }

        const favoriteDocRef = doc(db, "favorite_products", data?.productId);

        if (isFavorite) {
            // Remove user's ID from the favorites
            await updateDoc(favoriteDocRef, {
                [`user_ids.${user.uid}`]: false
            });
        } else {
            // Add user's ID to the favorites
            await setDoc(favoriteDocRef, {
                user_ids: {
                    [user.uid]: true
                }
            }, { merge: true });
        }

        setIsFavorite(!isFavorite);
    };

    const handleClose = () => {
        setShowModal(false); // Function to close the modal
    };

    return (
        <div className="ProductCard">
            <Link to={`/ProductDetail/${data?.productId}`}>
            <div className="product-slider">
                <Slider {...settings}>
                    <div>
                        <div className="product-img">
                            <Image src={data?.plain_photo_1} />
                            <span className={scoreClassText.className}>{scoreClassText.text}</span>
                            <i className={`favourite-ico ${isFavorite ? 'favorite' : ''}`} onClick={toggleFavorite}>
                            <Icon icon={isFavorite ? "ph:heart-fill" : "ph:heart-bold"} style={{ color: isFavorite ? '' : 'black' }} />
                            </i>
                        </div>
                    </div>
                    <div>
                        <div className="product-img">
                            <Image src={data?.colored_photo} />
                            <span className={scoreClassText.className}>{scoreClassText.text}</span>
                            <i className={`favourite-ico ${isFavorite ? 'favorite' : ''}`} onClick={toggleFavorite}>
                            <Icon icon={isFavorite ? "ph:heart-fill" : "ph:heart-bold"} style={{ color: isFavorite ? '' : 'black' }} />
                            </i>
                        </div>
                    </div>
                    <div>
                        <div className="product-img">
                            <Image src={data?.plain_photo_2} />
                            <span className={scoreClassText.className}>{scoreClassText.text}</span>
                            <i className={`favourite-ico ${isFavorite ? 'favorite' : ''}`} onClick={toggleFavorite}>
                            <Icon icon={isFavorite ? "ph:heart-fill" : "ph:heart-bold"} style={{ color: isFavorite ? '' : 'black' }} />
                            </i>
                        </div>
                    </div>
                </Slider>
            </div>
            <div className="ProductCard-Info">
                <div className="ProductCard-Detail">
                    <h5><span>{data?.name}</span></h5>
                    {/* <p> {data?.brand} • <i> with {data?.overview_count > 1000 ? formatReviewCount(data?.overview_count) : data?.overview_count} reviews </i> • 
                    <b>{hashtag}</b></p> */}
                    <p> {data?.brand} • <i> with {data?.overview_count > 1000 ? formatReviewCount(data?.overview_count) : data?.overview_count} reviews </i> </p>
                    <h6>${data?.price}</h6>
                </div>
                <div className="score-card-score-general">
                    <h4>{score} <span><Icon icon="mdi:chevron-up-circle-outline" /></span></h4>
                    <i>dotScore</i>
                    <small className={scoreClassIcon.className}><Icon icon={scoreClassIcon.icon} /></small>
                </div>
            </div>
            </Link>
            {/* Modal for Sign-In */}
            {showModal && (
                <Modal show={showModal} className="SignIn-Modal" onHide={handleClose}>
                    <Modal.Body>
                        <div className="Gradients-content">
                            <div className="Gradients-Title-Icons">
                                <Link to="#" onClick={handleClose}>
                                    <Icon icon="octicon:x-24" />
                                </Link>
                            </div>
                        </div>
                        <SignIn handleClose={handleClose} />
                    </Modal.Body>
                </Modal>
            )}
        </div>
    );
}

export default ProductCard;
