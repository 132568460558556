import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { BarLoader } from 'react-spinners';
import Footer from '../../component/Footer/Footer';
import './BundleCheckout.css';
import '../../component/MobileBundleDetail/MobileBundleDetail.css';

function BundleCheckout() {
    const navigate = useNavigate();
    const location = useLocation();
    const { bundle, product } = location.state || {};
    const [selectedOption, setSelectedOption] = useState('monthly');
    const [selectedFrequency, setSelectedFrequency] = useState('bi-monthly');
    const [userId, setUserId] = useState(null);
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);

    const nextPaymentDate = new Date();
    nextPaymentDate.setFullYear(nextPaymentDate.getFullYear() + 1);
    const formattedNextPaymentDate = nextPaymentDate.toLocaleDateString();

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handlePreviousStep = () => {
        setStep(step - 1);
    };

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserId(user.uid);
            } else {
                navigate('/');
            }
        });
    }, [navigate]);

    const calculatePrices = (item, isBundle) => {
        if (isBundle) {
            const monthlyPrice = (item?.price * 2).toFixed(2);
            const bimonthlyPrice = item?.price?.toFixed(2);
            const quarterlyPrice = (item?.price * (2 / 3)).toFixed(2);

            const monthlyAnnualPrice = (((parseFloat(monthlyPrice) + 5) * 12 * .9)).toFixed(2);
            const bimonthlyAnnualPrice = (((parseFloat(bimonthlyPrice) + 5) * 12 * .9)).toFixed(2);
            const quarterlyAnnualPrice = (((parseFloat(quarterlyPrice) + 5) * 12 * .9)).toFixed(2);

            return {
                monthlyPrice,
                bimonthlyPrice,
                quarterlyPrice,
                monthlyAnnualPrice,
                bimonthlyAnnualPrice,
                quarterlyAnnualPrice,
                serviceCharge: 5,
            };
        } else {
            const monthlyPrice = (item?.price / 1).toFixed(2);
            const bimonthlyPrice = (item?.price / 2).toFixed(2);
            const quarterlyPrice = (item?.price / 3).toFixed(2);

            const monthlyAnnualPrice = (((parseFloat(monthlyPrice) + 12) * 12) * .9).toFixed(2);
            const bimonthlyAnnualPrice = (((parseFloat(bimonthlyPrice) + 7) * 12) * .9).toFixed(2);
            const quarterlyAnnualPrice = (((parseFloat(quarterlyPrice) + 5.34) * 12) * .9).toFixed(2);

            return {
                monthlyPrice,
                bimonthlyPrice,
                quarterlyPrice,
                monthlyAnnualPrice,
                bimonthlyAnnualPrice,
                quarterlyAnnualPrice,
                serviceCharge: {
                    monthly: 12,
                    bimonthly: 7,
                    quarterly: 5.34,
                },
            };
        }
    };

    const {
        monthlyPrice,
        bimonthlyPrice,
        quarterlyPrice,
        monthlyAnnualPrice,
        bimonthlyAnnualPrice,
        quarterlyAnnualPrice,
        serviceCharge,
    } = calculatePrices(bundle || product, !!bundle);

    const annualPrice =
        selectedFrequency === 'bi-monthly'
            ? bimonthlyAnnualPrice
            : selectedFrequency === 'quarterly'
            ? quarterlyAnnualPrice
            : monthlyAnnualPrice;

    const annualSavings = (annualPrice * (.1)).toFixed(2);

    const chosenPrice =
        selectedOption === 'monthly'
            ? parseFloat(monthlyPrice) + (bundle ? 0 : serviceCharge.monthly)
            : parseFloat(annualPrice);

    const monthlyChosenPrice =
        selectedFrequency === 'bi-monthly'
            ? parseFloat(bimonthlyPrice) + (bundle ? 0 : serviceCharge.bimonthly)
            : selectedFrequency === 'quarterly'
            ? parseFloat(quarterlyPrice) + (bundle ? 0 : serviceCharge.quarterly)
            : parseFloat(monthlyPrice) + (bundle ? 0 : serviceCharge.monthly);
        

    const finalPrice =
        selectedOption === 'annual' ? chosenPrice : monthlyChosenPrice;

    const monthlyAnnualPriceId = bundle?.monthly_annual_subscription_priceid_test || product?.monthly_annual_subscription_priceid_test;
    const bimonthlyAnnualPriceId = bundle?.bimonthly_annual_subscription_priceid_test || product?.bimonthly_annual_subscription_priceid_test;
    const quarterlyAnnualPriceId = bundle?.quarterly_annual_subscription_priceid_test || product?.quarterly_annual_subscription_priceid_test;

    const monthlyPriceId = bundle?.monthly_subscription_priceid_test || product?.monthly_subscription_priceid_test;
    const bimonthlyPriceId = bundle?.bimonthly_subscription_priceid_test || product?.bimonthly_subscription_priceid_test;
    const quarterlyPriceId = bundle?.quarterly_subscription_priceid_test || product?.quarterly_subscription_priceid_test;

    const stripeProductId = bundle?.stripe_productid_test || product?.stripe_productid_test;

    const handleCheckout = async () => {
        setLoading(true);
        const priceId =
            selectedOption === 'annual'
                ? selectedFrequency === 'bi-monthly'
                    ? bimonthlyAnnualPriceId
                    : selectedFrequency === 'quarterly'
                    ? quarterlyAnnualPriceId
                    : monthlyAnnualPriceId
                : selectedFrequency === 'bi-monthly'
                ? bimonthlyPriceId
                : selectedFrequency === 'quarterly'
                ? quarterlyPriceId
                : monthlyPriceId;

        try {
            const response = await fetch('https://us-central1-shopdot-b9a58.cloudfunctions.net/createSubscription', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    customerId: userId,
                    priceId,
                    orderDetails: { name: bundle?.name || product?.name, option: selectedOption },
                }),
            });

            const { clientSecret, subscriptionId, stripeId } = await response.json();

            navigate('/Checkout', {
                state: {
                    clientSecret,
                    subscriptionId,
                    bundle,
                    product,
                    stripeId,
                    finalPriceRaw: finalPrice,
                    annualPriceRaw: annualPrice,
                    frequency: selectedFrequency,
                },
            });
        } catch (error) {
            console.error('Error creating subscription:', error);
            setLoading(false); // Ensure loading is stopped in case of an error
        }
    };

    return (
        <div className="main-content">
            <Link
                to="#"
                onClick={() => navigate(-1)}
                className="back-btn back-btn-reletive"
                style={{ marginLeft: '24px', marginBottom: '0px' }}
            >
                <Icon icon="ph:arrow-left" />
            </Link>
            <div className="bundle-for-you" style={{ marginTop: '12px', marginLeft: '24px', padding: '0px' }}>
                <h3>Order your <span className="bundle-for-you-header-special">{bundle?.name || product?.name}</span> today!</h3>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {step === 1 && (
                    <div className="yearly-choice-box">
                        <div className="yearly-choice-header">
                            <b>Select how often you want to receive your re-fills?</b> 
                            {bundle ? (
                                <span>($5 service charge per month will be added at checkout)</span>
                            ) : (
                                <>
                                    {selectedFrequency === 'monthly' && <span>($12 shipping and service charge per month will be added at checkout)</span>}
                                    {selectedFrequency === 'bi-monthly' && <span>($7 shipping and service charge per month will be added at checkout)</span>}
                                    {selectedFrequency === 'quarterly' && <span>($5.34 shipping and service charge per month will be added at checkout)</span>}
                                </>
                            )}
                        </div>
                        <div className="options-container">
                            <div
                                className={`option-box ${selectedFrequency === 'bi-monthly' ? 'selected' : ''}`}
                                onClick={() => setSelectedFrequency('bi-monthly')}
                            >
                                <div className="yearly-choice-header">
                                    <b style={{
                                        background: 'linear-gradient(119deg, #00FF6F -72.63%, #0052FF 110.96%)',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        display: 'inline-block'
                                    }}>
                                        Every 2 months for ${bimonthlyPrice}/month
                                    </b> Our top pick! You'll be stocked up with some extra.
                                </div>
                            </div>
                            <div
                                className={`option-box ${selectedFrequency === 'monthly' ? 'selected' : ''}`}
                                onClick={() => setSelectedFrequency('monthly')}
                            >
                                <div className="yearly-choice-header"><b> Every month for ${monthlyPrice}/month </b> You'll be buying extra but you'll have lots of product left over! </div>
                            </div>
                            {/* <div
                                className={`option-box ${selectedFrequency === 'quarterly' ? 'selected' : ''}`}
                                onClick={() => setSelectedFrequency('quarterly')}
                            >
                                <div className="yearly-choice-header"><b> Every 3 months for ${quarterlyPrice}/month</b> You'll have just enough but you'll save with this option. </div>
                            </div> */}
                        </div>
                        <button onClick={handleNextStep} className="pointer-button">Next <Icon icon="ph:arrow-right" /></button>
                    </div>
                )}
                {step === 2 && (
                    <div className="yearly-choice-box">
                        <div className="yearly-choice-header">
                            <b>Save ${annualSavings} over a year by subscribing annually!</b> Or, subscribe monthly.
                        </div>
                        <div className="options-container">
                            <div
                                className={`option-box ${selectedOption === 'monthly' ? 'selected' : ''}`}
                                onClick={() => setSelectedOption('monthly')}
                            >
                                <div className="yearly-choice-header"><b>Subscribe monthly. </b> Pay ${monthlyChosenPrice}/month today</div>
                            </div>
                            <div
                                className={`option-box ${selectedOption === 'annual' ? 'selected' : ''}`}
                                onClick={() => setSelectedOption('annual')}
                            >
                                <div className="yearly-choice-header">
                                    <b style={{
                                        background: 'linear-gradient(119deg, #00FF6F -72.63%, #0052FF 110.96%)',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        display: 'inline-block'
                                    }}>
                                        Subscribe annually.
                                    </b> Pay ${annualPrice}. Next payment: {formattedNextPaymentDate}.
                                </div>
                            </div>
                        </div>
                        {userId && (
                            <div className="pay-now-wrapper">
                                <button className="pay-now-button" onClick={handleCheckout} disabled={loading}>
                                    {loading ? <BarLoader color="#fff" height={4} width={100} /> : <><Icon icon="ph:stripe-logo" /> Pay with Stripe • ${finalPrice.toFixed(2)}</>}
                                </button>
                            </div>
                        )}
                        <button className="back-button" onClick={handlePreviousStep}> <Icon icon="ph:arrow-left" /> Previous </button>
                    </div>
                )}
            </div>
            <div style={{ marginTop: '150px' }}>
                <Footer />
            </div>
        </div>
    );
}

export default BundleCheckout;
