import { Link } from 'react-router-dom';
import ReviewsDetailsBox from '../ReviewsDetailsBox/ReviewsDetailsBox';
import './ReviewsDetails.css';
import { Icon } from '@iconify/react';
import NoInfoCard from '../NoInfoCard/NoInfoCard';
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useState } from 'react';
import ProductSelector from '../ProductSelector/ProductSelector';


function ReviewsDetails({ data, setReviewLimit, type, onFilterChange }) {
    const [hasMore, setHasMore] = useState(true);

    const handleSeeMore = () => {
        setReviewLimit((prevLimit) => prevLimit + 4);
    };

    return (
        <>
            <div className="search-box">
                <input
                    type="text"
                    placeholder="search reviews & more"
                />
                <Link to="#">
                    <Icon icon="prime:search" />
                </Link>
            </div>
            {type==='sunscreen' && <ProductSelector type="skintype" onSelectFilter={onFilterChange}/>}
            <div id="scrollableDiv">
                <InfiniteScroll
                    dataLength={data?.length || 0}
                    next={handleSeeMore}
                    hasMore={hasMore}
                    loader={<div className="loading my-4"></div>}
                >
            <div className='ReviewsDetails-list'>
                {data?.length ? data?.map((item, index) => (
                    <ReviewsDetailsBox data={item} key={index} />
                )) : <NoInfoCard />}
            
            </div>
            </InfiniteScroll>
            </div>
        </>
    );
}

export default ReviewsDetails;
