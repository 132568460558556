import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.css";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const queryClient = new QueryClient();
// const stripePromise = loadStripe('pk_live_51PMDQkC2N5akj7rTONYKgQVwgEQseDJPY3rYG45mpXxWL3mvOwr7r7m7WhgqCHUAdjQrVTXAcEFhRnLEfRGL6CQE00p7skwd53');

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        {/* <Elements stripe={stripePromise}> */}
          <App />
        {/* </Elements> */}
      </QueryClientProvider> 
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
