import React, { useEffect, useState, Suspense, lazy } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Nav, Tab } from "react-bootstrap";
import ProductActivity from "../../component/ProductActivity/ProductActivity";
import "./ProductDetail.css";
import { db } from "../../utils/firebase-config";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useQuery } from "react-query";
import { createContext } from "react";
const MobileImageProductDetail = lazy(() => import('../../component/MobileImageProductDetail/MobileImageProductDetail'));
const DesktopImageProductDetail = lazy(() => import('../../component/DesktopImageProductDetail/DesktopImageProductDetail'));
const DesktopProductDetails = lazy(() => import('../../component/DesktopProductDetails/DesktopProductDetails'));
const ProductDetails = lazy(() => import('../../component/ProductDetails/ProductDetails'));

export const ProductContext = createContext();

const fetchProductById = async (productId) => {
  const productDocRef = doc(db, "products", productId);
  const productDoc = await getDoc(productDocRef);

  if (!productDoc.exists()) {
    throw new Error("Product not found!");
  }

  const productData = productDoc.data();
  const productType = productData.product_type;

  const baseReviewQueryOptions = [
    collection(productDocRef, "reviews"),
    limit(4)
  ];

  let reviewPromises = [];
  let reviewCategories = []; // To keep track of categories in the order they're added
  if (productType === "cleanser") {
    reviewPromises.push(
      getDocs(query(...baseReviewQueryOptions, where("removes_makeup", "==", true), orderBy("removes_makeup_confidence", "desc"))),
      getDocs(query(...baseReviewQueryOptions, where("acne_label", "==", true), orderBy("acne_confidence", "desc")))
    );
    reviewCategories.push("removes makeup", "acne");
  } else {
    reviewPromises.push(
      getDocs(query(...baseReviewQueryOptions, where("works_label", "==", true), ...(productType !== "sunscreen" ? [orderBy("works_confidence", "desc")] : []))),
      getDocs(query(...baseReviewQueryOptions, where("acne_label", "==", true), orderBy("acne_confidence", "desc"))),
      getDocs(query(...baseReviewQueryOptions, where("texture_label", "==", true))),
      getDocs(query(...baseReviewQueryOptions, where("smell_label", "==", true), orderBy("smell_confidence", "desc")))
    );
    reviewCategories.push("hydration", "acne", "texture", "smell");
  }

  reviewPromises.push(
    getDocs(query(collection(productDocRef, "feature"))),
    getDocs(query(collection(productDocRef, "ingredients"))),
    getDocs(query(collection(productDocRef, "categories")))
  );

  const results = await Promise.all(reviewPromises);

  // Structure the reviews by category
  let categorizedReviews = reviewCategories.reduce((acc, category, index) => {
    acc[category] = results[index].docs.map(doc => ({ ...doc.data(), reviewId: doc.id }));
    return acc;
  }, {});

  const featuresData = results[reviewPromises.length - 3].docs.map(doc => ({ ...doc.data(), featureId: doc.id }));
  const ingredientsData = results[reviewPromises.length - 2].docs.map(doc => doc.data());
  const categoriesData = results[reviewPromises.length - 1].docs.map(doc => doc.data())[0];

  return {
    ...productData,
    productId,
    reviews: categorizedReviews, // Now returns an object with categories as keys
    features: featuresData,
    ingredients: ingredientsData,
    categories: categoriesData,
  };
};


export function getScoreClassText(dry_score, oily_score, combo_score) {
  if (dry_score > 95 && oily_score > 95 && combo_score > 95) {
    return { className: "Rated-tag green-text", text: "top-rated for all" };
  }
  if (dry_score > 95 && oily_score > 95) {
    return {
      className: "Rated-tag green-text",
      text: "top-rated for dry and oily skin",
    };
  }
  if (dry_score > 95) {
    return {
      className: "Rated-tag green-text",
      text: "top-rated for dry skin",
    };
  }
  if (oily_score > 95) {
    return {
      className: "Rated-tag green-text",
      text: "top-rated for oily skin",
    };
  }
  if (dry_score < 55 || oily_score < 55 || combo_score < 55) {
    return { className: "Rated-tag yellow-text", text: "below average" };
  }
  return { className: "", text: "" };
}

export function getScoreClassIcon(score) {
  if (score > 95) {
    return { className: "green-box", icon: "ph:trophy" };
  } else if (score < 55) {
    return { className: "yellow-box", icon: "ph:toilet-paper" };
  } else {
    return "";
  }
}

export function SampleNextArrow(props) {
  // next button for slider
  const { className, style, onClick } = props;
  return (
      <>
      <div
      className={className}
      style={{ ...style, display: "block"}}
      onClick={onClick}>
      </div>
      </>
  );
}

export function SamplePrevArrow(props) {
  // previous button for slider
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}

function ProductDetail() {
  const { id } = useParams();
  const [productDetail, setProductDetail] = useState({});
  const { data: product, isLoading, isError, } = useQuery(["product", id], () => fetchProductById(id));
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    if (product) {
      setProductDetail(product);
    }
  }, [product]);

  const navigate = useNavigate();
  const settings = {
    dots: true,
    arrows: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  const score = product?.dotscore?.toFixed();
  const oily_score = product?.oily_score?.toFixed();
  const dry_score = product?.dry_score?.toFixed();
  const combo_score = product?.combo_score?.toFixed();

  const scoreClassText = getScoreClassText(dry_score, oily_score, combo_score);

  const scoreClassIcon = getScoreClassIcon(score);
  
  const handleBuy = (e) => {
    e.preventDefault();
    if (product) {
        const productName = String(product?.name);
        navigate('/BundleCheckout', {
            state: {
                product: product,
            },
        });
    } else {
        console.error('Product data is not available');
    }
};

  
  useEffect(() => {
    fetchProductById(id).then(product => {
      setProductDetail(product);
    });
  }, [id]);

  useEffect(() => {
    // resizing
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
  
    window.addEventListener('resize', handleResize);
  
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // console.log(product)

  return (
    <ProductContext.Provider value={{ product: productDetail }}>
      {isLoading ? (
        <div className="loading"></div>
      ) : (
        <div className="ProductDetails-content">
          <Suspense fallback={<div className="loading"></div>}>
            {!isMobile ? (
              <div className="product-details-desktop-images">
                <DesktopImageProductDetail/>
              </div>
            ) : (
              <div className="ProductDetails-content-slider">
                <MobileImageProductDetail settings={settings} navigate={navigate} handleBuy={handleBuy} scoreClassText={scoreClassText} scoreClassIcon={scoreClassIcon}/>
              </div>
            )}
          </Suspense>
          <div className="ProductDetails-content-info">
            <Container>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="produts-tabs-wrap">
                  <Nav variant="pills" className="produts-tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Details</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Explore</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Suspense fallback={<div className="loading"></div>}>
                    {!isMobile ? (
                      <div>
                        <DesktopProductDetails handleBuy={handleBuy}/>
                      </div>
                    ) : (
                      <div className="text-center">
                        <ProductDetails />
                      </div>
                    )}
                  </Suspense>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <ProductActivity productId={id} />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Container>
          </div>
        </div>
      )}
    </ProductContext.Provider>
  );
}

export default ProductDetail;
