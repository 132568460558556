import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./IngredientsCard.css";
import NoInfoCard from "../NoInfoCard/NoInfoCard";

function IngredientsCard({ items, setingredientsLimit, isLoading }) {
  return (
    <>
    <div className="Gradients-Card-List default-card">
      {isLoading ? (
        <div className="loading"></div>
      ) : (
        <>
          {items?.map((item, index) => (
            <div className="Gradients-Card" key={index}>
              <div className="Ingredients-Wrap">
                <i>
                  <Icon icon="ph:drop" />
                </i>
                <h6>
                  {item?.name} <span>for hydration</span>
                </h6>
              </div>
              <p>{item?.preview}</p>
              <div className="warning-post post-action">
                <Link to="/ReviewDetail2" className="mb-0">
                  <i>
                    <Icon icon="ph:warning-octagon" />
                  </i>{" "}
                  related to <b>‘reaction to acne’</b>{" "}
                  <span>
                    <Icon icon="ph:caret-right-bold" />
                  </span>
                </Link>
              </div>
            </div>
          ))}
          {items?.length > 0 && <div className="text-center">
            <Link
              className="btn btn-gray btn-rounded btn-see-more mt-0"
              to="#"
              onClick={() => setingredientsLimit((prev) => prev + 4)}
            >
              See More
            </Link>
          </div>}
        </>
      )}
    </div>
    {items?.length === 0 && <NoInfoCard />}
    </>
  );
}

export default IngredientsCard;
