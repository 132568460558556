import React from 'react';
import { useCart } from './CartContext';
import { Link } from 'react-router-dom';

const ShoppingCart = () => {
  const { cart, removeFromCart, updateQuantity } = useCart();

  const handleIncreaseQuantity = (productName) => {
    updateQuantity(productName, 1);
  };

  const handleDecreaseQuantity = (productName) => {
    updateQuantity(productName, -1);
  };

  return (
    <div>
      <h1>Shopping Cart</h1>
      <ul>
        {cart.map(product => (
          <li key={product.id}>
            <p>{product.name}</p>
            <p>${product.price}</p>
            <p>Quantity: {product.quantity}</p>
            <button onClick={() => handleDecreaseQuantity(product.name)}>-</button>
            <button onClick={() => handleIncreaseQuantity(product.name)}>+</button>
            <button onClick={() => removeFromCart(product.name)}>Remove</button>
          </li>
        ))}
      </ul>
      <Link to="/Checkout">
        <button>Proceed to Checkout</button>
      </Link>
    </div>
  );
};

export default ShoppingCart;
