import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import "./SignIn.css";
import { Icon } from "@iconify/react";
import { useNavigate, useLocation } from 'react-router-dom';

function SignIn({ handleClose }) {
  const auth = getAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (successMessage) {
      if (location.pathname === '/SignUp' || location.pathname === '/ForgotPass') {
        navigate('/');
      } else {
        handleClose();
      }
    }
  }, [successMessage, location.pathname, navigate, handleClose]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setSuccessMessage('Successfully signed in!');
        setErrorMessage('');
        handleClose();
      })
      .catch((error) => {
        setErrorMessage(`${error.message}`);
        setSuccessMessage('');
      })
      .finally(() => {
        setIsLoading(false); // Stop loading
      });
  };

  const handleGoogleSignIn = (event) => {
    event.preventDefault(); // Prevent default link behavior
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // Handle sign-in success
        const user = result.user;
        setSuccessMessage('Successfully signed in with Google!');

        setTimeout(() => {
          handleClose();
        }, 1000); // Close modal on success
      })
      .catch((error) => {
        // Handle Errors here.
        setErrorMessage(`Google sign-in error: ${error.message}`);
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="SignIn-Modal">
      <div className="SignIn-popup">
        <div className='SignIn-Header-Title'>Sign In</div>
        <div className='SignIn-SubHeader-Title'>Save favorites, like content & more!</div>
        <form onSubmit={handleSubmit}>
          <div className="Box-Header">Email</div>
          <div className="input-container">
          <input 
            className="input-box" 
            type="email" 
            placeholder="Email" 
            onChange={(e) => setEmail(e.target.value)} 
          />
          </div>
          <div className='Box-Header'> Password </div>
          <div className="input-container">
          <input 
              className="input-box" 
              type={showPassword ? "text" : "password"} 
              placeholder="Password" 
              onChange={(e) => setPassword(e.target.value)}
          />
          <button type="button" onClick={togglePasswordVisibility}>
              {showPassword ? <Icon icon="ph:eye-slash" style={{ fontSize: '20px' }}/> : <Icon icon="ph:eye" style={{ fontSize: '20px' }}/>}
          </button>
          </div>
          <div className="forgot-password">
          <Link to="/ForgotPass" className="forgot-password-bold" onClick={handleClose}>Reset Password</Link>
          </div>
          <Button type="submit" className="submit-btn" style={{backgroundColor:"black"}}>Sign In</Button>
          {isLoading && <div className="loading"></div>}
        </form>
        <div className="mid-line">
          <div className="line"></div>
          <span className="text">or</span>
          <div className="line"></div>
        </div>
        <div>
          <Link className="signup-btn" onClick={handleGoogleSignIn}> <Icon icon="fa6-solid:g" /> Sign In with Google</Link>
        </div>
        <div className='forgot-password'>{"Don't have an account? "}
           <Link to="/SignUp" className="signup-link" onClick={handleClose}>Sign up</Link>
        </div>
      </div>
      {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
}

export default SignIn;
