import React from "react";
import './NoInfoCard.css'


function NoInfoCard() {

    return (
        <div className="no-info-card default-card">
            <h5>We don’t have this information, yet.</h5>
            <p>Stay tuned, it’s <b>coming soon!</b></p>
        </div>
    );
}

export default NoInfoCard;
