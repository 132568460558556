import { Icon } from '@iconify/react'
import React from 'react'
import './DotScore.css'

export default function DotScore({dotscore, grey}) {
    const getScoreClassIcon = (score) => {
        if (score > 90) {
            return { className: "green-box", icon: "ph:trophy" };
        }  else {
            return { className: "", icon: "" }; // Ensure to handle this case
        }
        };

        const scoreClassIcon = dotscore ? getScoreClassIcon(dotscore.toFixed()) : { className: "", icon: "" };
return (


    <div className={"score-card-score" + (!grey ? "" : " score-card-score-grey")}>
        <h4>
        {dotscore?.toFixed()}{" "}
        <span>
            <Icon icon="mdi:chevron-up-circle-outline" />
        </span>
        </h4>
        <i>dotScore</i>
            <small className={scoreClassIcon.className}>
            <Icon icon={scoreClassIcon.icon} />
        </small>
    </div>
  )
}
