// Import the functions you need from the SDKs you need
import { getApp, getApps, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCpjCkppSDDogvSFuiBavDX8WnYFngeImg",
  authDomain: "shopdot-b9a58.firebaseapp.com",
  projectId: "shopdot-b9a58",
  storageBucket: "shopdot-b9a58.appspot.com",
  messagingSenderId: "324802264883",
  appId: "1:324802264883:web:c69a41df87e1b4b83b4846",
  measurementId: "G-P1HJKNZNNN"
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

export const db = getFirestore(app);
export const storage = getStorage(app);