import React, { useEffect, useState } from "react";
import { Nav, Tab, Container, Button, Modal, Row, Col } from "react-bootstrap";
import ProductActivity from "../../component/ProductActivity/ProductActivity";
import Moisturizers from "../../component/Moisturizers/Moisturizers";
import './ProductPage.css';
import "../WhatsSkinType/WhatsSkinType.css";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import LearnMore from "../../component/LearnMore/LearnMore";


function ProductPage() {
    const [show, setShow] = useState(false);
    const [showLearn, setShowLearn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // The initial Learn More popup. Sets a timer to allow the person to click away after 1.5 seconds
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1500);

        // Clear the timeout if the component is unmounted
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        // Check if the user has visited before
        const hasVisited = localStorage.getItem("hasVisited");

        if (!hasVisited) {
            // User is new, show the popup
            setShowLearn(true);
            // Set the flag in local storage
            localStorage.setItem("hasVisited", "true");
        }
    }, []);

    const handleClose = (val) => {
        if (!isLoading) {
            setShowLearn(false);
        }
        setShow(false)
        setShowLearn(false)
    };

    return (
        <div className="main-content ProductPage-content">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="produts-tabs-wrap">
                    <Nav variant="pills" className="produts-tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Shop</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Explore</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <Container>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <Moisturizers MoisturizersTitle="Your next go-to?"/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <ProductActivity />
                        </Tab.Pane>
                    </Tab.Content>
                </Container>
            </Tab.Container>
            <>
            </>
            <Modal show={showLearn} className="learn-more-modal">
                <Modal.Body>
                    <div className="Gradients-content">
                        <div className="Gradients-Title-Icons">
                            {isLoading ? (
                                <div className="loading-icon">
                                    <div className="loader-learn">
                                        <svg>
                                            <circle cx="10" cy="10" r="8" stroke="black"></circle>
                                        </svg>
                                    </div>
                                </div>
                            ) : (
                                <Link to="#" onClick={handleClose}>
                                    <Icon icon="octicon:x-24" />
                                </Link>
                            )}
                        </div>
                    </div>
                    <LearnMore handleClose={handleClose}/>
                </Modal.Body>
            </Modal>
            
        </div>
    );
}

export default ProductPage;
