import React, { useEffect, useState, lazy } from "react";
import ProductCard from "../ProductCard/ProductCard";
import { Link } from "react-router-dom";
import { collection, getDocs, query, limit, orderBy, startAfter, where, doc, getDoc } from "firebase/firestore";
import { db } from "../../utils/firebase-config";
import "./Moisturizers.css";
import { useQuery } from "react-query";
import { Icon } from "@iconify/react";
import InfiniteScroll from "react-infinite-scroll-component";
import NoInfoCard from "../NoInfoCard/NoInfoCard";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ProductSelector from "../ProductSelector/ProductSelector";
import Modal from "react-bootstrap/Modal";
import WhatsSkinType from "../../Pages/WhatsSkinType/WhatsSkinType";
import cleanserheader from "../../images/cleanserheader.png";
import moisturizerheader from "../../images/skintypeheader.png";
import sunscreenheader from "../../images/whitecastheader.png";
import BundleCard from "../BundleCard/BundleCard";
// const cleanserheader = lazy(() => import('../../images/cleanserheader.png'));
// const moisturizerheader = lazy(() => import('../../images/skintypeheader.png'));
// const sunscreenheader = lazy(() => import('../../images/whitecastheader.png'));


const fetchProductsList = async (productsLimit, startAfterDoc, searchTerm, filterData, filterId) => {
  if (filterId === "bundles") {
    const bundlesCollectionRef = collection(db, "bundles");
    let bundlesQuery = query(bundlesCollectionRef, orderBy("order"));

    if (startAfterDoc) {
      bundlesQuery = query(bundlesQuery, startAfter(startAfterDoc));
    }

    const snapshot = await getDocs(bundlesQuery);
    const bundles = snapshot.docs.map(doc => ({
      ...doc.data(),
      productId: doc.id,
    }));

    return {
      products: bundles,
      lastDoc: snapshot.docs[snapshot.docs.length - 1],
      totalDoc: snapshot.size,
    };
  }

  const productsCollectionRef = collection(db, "products");
  let productQueries = [];

  // Determine the primary orderBy field based on applied filters
  let primaryOrderByField = filterData.order === "dotscore" ? "dotscore" : "price";
  let primaryOrderDirection = filterData.order === "dotscore" ? "desc" : "asc";

  // Helper function to create a query with appropriate orderBy and where clauses based on skin type
  const createQueryWithSkinType = (skinTypeField, threshold) => {
    let q = query(
      productsCollectionRef,
      where("product_type", "==", filterId),
      where(skinTypeField, '>', threshold),
      where("hidden", "==", false),
      orderBy(skinTypeField, "desc"),
    );

    // if (filterData.noWhiteCast) {
    //   q = query(q, where("whitecast_score", ">", 0.83));
    // }

    if (primaryOrderByField !== skinTypeField) {
      q = query(q, orderBy(primaryOrderByField, primaryOrderDirection));
    }

    if (startAfterDoc) {
      q = query(q, startAfter(startAfterDoc));
    }

    return q;
  };

  // Generate queries based on skin type filters
  if (filterData.dry) {
    productQueries.push(createQueryWithSkinType('dry_score', 83));
  }
  if (filterData.oily) {
    productQueries.push(createQueryWithSkinType('oily_score', 83));
  }
  if (filterData.normal) {
    productQueries.push(createQueryWithSkinType('combo_score', 83));
  }
  if (filterData.noWhiteCast) {
    productQueries.push(createQueryWithSkinType('whitecast_score', 93));
  }
  if (filterData.goodUnderMakeup) {
    productQueries.push(createQueryWithSkinType('makeup_score', 77));
  }
  if (filterData.goodForAcne) {
    productQueries.push(createQueryWithSkinType('acne_score', 80));
  }
  if (filterData.goodRemoveMakeup) {
    productQueries.push(createQueryWithSkinType('removes_makeup_score', 83));
  }
  if (filterData.chemical) {
    productQueries.push(createQueryWithSkinType('chemical', "==", true));
  } 
  if (filterData.mineral) {
    productQueries.push(createQueryWithSkinType('mineral', "==", true));
  }
  if (filterData.oilBased) {
    productQueries.push(createQueryWithSkinType('type', "==", "gel"));
  }
  if (filterData.waterBased) {
    productQueries.push(createQueryWithSkinType('type', "==", "water"));
  }

  // If no specific skin type filter is applied, use a base query
  if (productQueries.length === 0) {
    let baseQuery = query(productsCollectionRef, where("product_type", "==", filterId), where("hidden", "==", false), orderBy(primaryOrderByField, primaryOrderDirection), limit(productsLimit));
  
    if (startAfterDoc) {
      baseQuery = query(baseQuery, startAfter(startAfterDoc));
    }
    if (searchTerm) {
      baseQuery = query(
        productsCollectionRef,
        where("product_type", "==", filterId),
        where("hidden", "==", false),
        where("search_terms", ">=", searchTerm.toLowerCase()),
        orderBy("search_terms"), // Ensure this is the first orderBy field
        orderBy(primaryOrderByField, primaryOrderDirection),
        limit(productsLimit)
      );
    }
    productQueries.push(baseQuery);
  }

  // Execute the queries
  let productsSnapshot = [];
  for (let q of productQueries) {
    const snapshot = await getDocs(q);
    snapshot.docs.forEach(doc => {
      if (!productsSnapshot.some(d => d.id === doc.id)) { // Avoid duplicates
        productsSnapshot.push(doc);
      }
    });
  }

  // Filter by search term if necessary
  if (searchTerm) {
    const searchTermLower = searchTerm.toLowerCase();
    productsSnapshot = productsSnapshot.filter(doc => 
      doc.data().search_terms.includes(searchTermLower)
    );
  }

  // Map to desired output format
  const products = productsSnapshot.map(doc => ({
    ...doc.data(),
    productId: doc.id,
  }));

  return {
    products,
    lastDoc: productsSnapshot[productsSnapshot.length - 1],
    totalDoc: productsSnapshot.length, // This might need adjustment based on actual requirements
  };
};

function Moisturizers({ MoisturizersTitle, favoritesOnly, user }) {
  const productsLimit = 18;
  const [startAfterDoc, setStartAfterDoc] = useState(null);
  const [productsList, setProductsList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [numberOfDoc, setNumberOfDoc] = useState();
  const [hasMore, setHasMore] = useState(true);
  const auth = getAuth();
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [filterId, setFilterId] = useState('bundles');
  const [showWhatsSkinTypeModal, setShowWhatsSkinTypeModal] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  const [filterData, setFilterData] = useState({
    dry: false,
    oily: false,
    normal: false,
    order: "dotscore",
    noWhiteCast: false,
    chemical: false,
    mineral: false,
    goodUnderMakeup: false,
    goodForAcne: false,
    oilBased: false,
    waterBased: false,
    goodRemoveMakeup: false
  });

  useEffect(() => {
    setFilterData({
      dry: false,
      oily: false,
      normal: false,
      order: "dotscore",
      noWhiteCast: false,
      chemical: false,
      mineral: false,
      goodUnderMakeup: false,
      goodForAcne: false,
      oilBased: false,
      waterBased: false,
      goodRemoveMakeup: false
    });
  }, [filterId]);

  const handleCheckboxChange = (key) => {
    setFilterData(prevFilterData => ({
      ...prevFilterData,
      [key]: !prevFilterData[key]
    }));
  };

  const handleRadioChange = (key) => {
    setFilterData(prevFilterData => ({
      ...prevFilterData,
      dry: key === "dry",
      oily: key === "oily",
      normal: key === "normal"
    }));
  };

  const {
    data: products,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["productsList", productsLimit, startAfterDoc, searchTerm, filterData, filterId],
    () => fetchProductsList(productsLimit, startAfterDoc, searchTerm, filterData, filterId),
    {
      enabled: !favoritesOnly // Only run this query if favoritesOnly is false
    }
  );

  useEffect(() => {
    if (favoritesOnly && user) {
      const fetchFavoriteProducts = async () => {
        try {
          const favoriteProductsRef = collection(db, "favorite_products");
          const q = query(favoriteProductsRef, where(`user_ids.${user?.uid}`, "==", true));
          const querySnapshot = await getDocs(q);

          const favoriteProductIds = querySnapshot.docs.map(doc => doc.id);
          const favoriteProducts = await Promise.all(favoriteProductIds.map(async (productId) => {
            const productRef = doc(db, "products", productId);
            const productSnap = await getDoc(productRef);
            return {
              ...productSnap.data(),
              productId: productId
            };
          }));

          const nonBundleProducts = favoriteProducts.filter(product => product.product_type !== "bundles");

          setProductsList(nonBundleProducts);
        } catch (error) {
          console.error("Error fetching favorite products:", error);
        }
      };

      fetchFavoriteProducts();
    }
  }, [user, favoritesOnly]);

  useEffect(() => {
    if (products) {
      if (startAfterDoc) {
        const newProducts = products.products.filter(
          (product) =>
            !productsList.some(
              (existingProduct) =>
                existingProduct.productId === product.productId
            )
        );
        if (newProducts.length > 0) {
          setProductsList((prev) => [...prev, ...newProducts]);
        }
      } else {
        setProductsList(products.products);
      }
      setNumberOfDoc(products.totalDoc);
    }
  }, [products, productsList]);

  const handleSeeMore = () => {
    if (products && products.lastDoc) {
      setStartAfterDoc(products.lastDoc);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsUserSignedIn(true);
        setCurrentUser(user);
      } else {
        setIsUserSignedIn(false);
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    setStartAfterDoc(null);
    setHasMore(true);
    setProductsList([]);
  }, [filterData, filterId]);

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleWhatsSkinTypeModal = () => setShowWhatsSkinTypeModal(!showWhatsSkinTypeModal);

  const handleSearchInputChange = (e) => {
    setProductsList([]);
    setHasMore(true);
    setSearchTerm(e.target.value);
    setStartAfterDoc(null);
    refetch();
  };

  if (favoritesOnly) {
    return (
      <div className="products-whatsSkinType-container">
        <div id="scrollableDiv">
          <InfiniteScroll
            dataLength={productsList?.length}
            next={() => handleSeeMore()}
            hasMore={hasMore}
            loader={isLoading && <div className="loading my-4"></div>}
          >
            <div className="ProductCardList">
              {productsList?.map((item, index) => (
                item.show !== 0 && (
                  <ProductCard data={item} key={index} user={currentUser} />
                )
              ))}
            </div>
          </InfiniteScroll>
          {!isLoading && productsList.length === 0 && <NoInfoCard />}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="search-box-top">
        <div className="search-box-products">
          <input
            type="text"
            placeholder="search your next go-to"
            onChange={handleSearchInputChange}
          />
          <Link to="#">
            <Icon icon="prime:search" />
          </Link>
        </div>
        <div className="filter-button-sort" onClick={toggleWhatsSkinTypeModal}>
          <a>Filter</a> <i><Icon className="filter-button-sort-icon" icon="ph:magic-wand" /></i>
        </div>
      </div>

      <ProductSelector onSelectFilter={setFilterId} />

      {searchTerm.length == 0 && <TypeInformation type={filterId} filter={filterData} onCheckboxChange={handleCheckboxChange} onRadioChange={handleRadioChange} isDesktop={isDesktop}/>}

      <div className="products-whatsSkinType-container">
        <div id="scrollableDiv">
          <InfiniteScroll
            dataLength={productsList?.length}
            next={() => handleSeeMore()}
            hasMore={hasMore}
            loader={isLoading && <div className="loading my-4"></div>}
          >
            <div className="ProductCardList">
              {productsList?.map((item, index) => (
                item.show !== 0 && (
                  filterId === "bundles" ? <BundleCard data={item} key={index} user={currentUser} /> : <ProductCard data={item} key={index} user={currentUser} />
                )
              ))}
            </div>
          </InfiniteScroll>
          {!isLoading && productsList.length === 0 && <NoInfoCard />}
        </div>
      </div>
      <Modal show={showWhatsSkinTypeModal} onHide={toggleWhatsSkinTypeModal}>
        <Modal.Body>
          <div className="Gradients-content">
            <div className="Gradients-Title-Icons">
              <Link to="#" onClick={() => toggleWhatsSkinTypeModal()}>
                <Icon icon="octicon:x-24" />
              </Link>
            </div>
          </div>
          <WhatsSkinType filter={filterData} type={filterId} handleClose={(updatedFilters) => {
            setFilterData(updatedFilters);
            toggleWhatsSkinTypeModal();
          }} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Moisturizers;

function TypeInformation({ type, filter, onCheckboxChange, onRadioChange, isDesktop }) {
  const getImageSource = () => {
    switch (type) {
      case "moisturizer":
        return moisturizerheader;
      case "cleanser":
        return cleanserheader;
      case "sunscreen":
        return sunscreenheader;
      default:
        return "";
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
      <div className="landing-header">
        <div>
          <h4 className="landing-header-top-font" style={{marginBottom: "2px"}}>
            {type === "moisturizer" ? "more, on shopdot" :
            type === "cleanser" ? "new!" :
            type === "sunscreen" ? "Find something better!"
            : "only on shopdot"}
          </h4>
          <h2 className="text-3xl font-bold">
            {type === "moisturizer" ?
              <>smart <span className="text-[#C69575]">moisturizer </span>suggestions by skin type</> :
              type === "cleanser" ?
              <>everyone needs a great <span className="text-[#C69575]">face wash</span></> :   
              type === "sunscreen" ?   
              <>Does the wrong <span className="text-[#C69575]">sunscreen </span>have you looking like a ghost?</>
              :  
              <>skincare routines aren't  <span className="text-[#C69575]">rocket science </span></>}
          </h2>
        </div>
        {type === "moisturizer" ?
          <>
            <p className="landing-body-top-font">
              Moisturizers affect dry, oily & combination skin differently because each have their own unique hydration needs & oil production.
            </p>
            <p className="landing-body-top-font">
              Let's find the right moisturizer for you. Start by selecting your skin type:
            </p>
          </>
          : type === "cleanser" ?
          <>
            <p className="landing-body-top-font">
              Dermatologists advise everyone to use daily face wash, regardless of makeup usage, to effectively remove buildup.
            </p>
            <p className="landing-body-top-font">
              Start here to find a great face wash for you:
            </p>
          </>
          : type === "sunscreen" ?
          <>
            <p className="landing-body-top-font">
              The wrong sunscreen can break you out, sit greasily under your makeup or leave a white cast on medium & dark skin tones.
            </p>
            <p className="landing-body-top-font">
              On shopdot, you can <b>shop the right sunscreen - </b> one that suits your skin tone and skin type! Start here:
            </p>
          </>
          :
          <>
            <p className="landing-body-top-font">
              We make skincare routines simple. Just choose what suits you, and be consistent. 
            </p>
            <p className="landing-body-top-font">
              On shopdot, you can set-up & stay consistent with <b>your skincare routine in just 2 minutes. </b> We’ve made this super easy. Start here:
            </p>
          </>
        }
        <span>
          {type === "moisturizer" ?
            <>
              <label htmlFor="dry-skin" className="checkbox-label custom-radio">
                <input type="radio" name="sortby" id="dry-skin" checked={filter.dry} onChange={() => onRadioChange("dry")} />
                <span></span>
                <p>dry skin</p>
              </label>
              <label htmlFor="oily-skin" className="checkbox-label custom-radio">
                <input type="radio" name="sortby" id="oily-skin" checked={filter.oily} onChange={() => onRadioChange("oily")} />
                <span></span>
                <p>oily skin</p>
              </label>
              <label htmlFor="combo-skin" className="checkbox-label custom-radio">
                <input type="radio" name="sortby" id="combo-skin" checked={filter.normal} onChange={() => onRadioChange("normal")} />
                <span></span>
                <p>combination skin</p>
              </label>
            </>
            : type === "cleanser" ?
            <>
              <label htmlFor="great-for-acne" className="checkbox-label flex items-center gap-2 cursor-pointer">
                <input type="checkbox" name="sortby" id="great-for-acne" checked={filter.goodForAcne} onChange={() => onCheckboxChange("goodForAcne")} />
                <span className="custom-checkbox"></span>
                <p>great for acne</p>
              </label>
              <label htmlFor="top-makeup-removal" className="checkbox-label flex items-center gap-2 cursor-pointer">
                <input type="checkbox" name="sortby" id="top-makeup-removal" checked={filter.goodRemoveMakeup} onChange={() => onCheckboxChange("goodRemoveMakeup")} />
                <span className="custom-checkbox"></span>
                <p>top-rated for makeup removal</p>
              </label>
            </>
            : type === "sunscreen" ?
            <>
              <label htmlFor="no-white-cast" className="checkbox-label flex items-center gap-2 cursor-pointer">
                <input type="checkbox" name="sortby" id="no-white-cast" checked={filter.noWhiteCast} onChange={() => onCheckboxChange("noWhiteCast")} />
                <span className="custom-checkbox"></span>
                <p>no white cast</p>
              </label>
              <label htmlFor="great-under-makeup" className="checkbox-label flex items-center gap-2 cursor-pointer">
                <input type="checkbox" name="sortby" id="great-under-makeup" checked={filter.goodUnderMakeup} onChange={() => onCheckboxChange("goodUnderMakeup")} />
                <span className="custom-checkbox"></span>
                <p>great under makeup</p>
              </label>
            </>
            :
            <div></div>
          }
        </span>
        {type === "bundles" && (
            <a href="https://form.typeform.com/to/fhw1kz60" className="follow-button" style={{padding: '12px', fontSize: '16px', width: '60%', minWidth: '250px'}}target="_blank" rel="noopener noreferrer">
              Create my routine quiz!
            </a>
        )}
        <div className="landing-header-shop-widget">shop our top picks <Icon icon={"ph:arrow-down"} /></div>
      </div>
      {isDesktop && type !== 'bundles' && (
        <div className="landing-header-image-container">
          <img 
            src={getImageSource()} 
            alt={`${type} product image`}
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
      )}
    </div>
  );
}