import { Button, Container, Nav, Tab } from "react-bootstrap";
import Moisturizers from "../../component/Moisturizers/Moisturizers";
import ProductActivity from "../../component/ProductActivity/ProductActivity";
import CreatorCard from "../../component/CreatorCard/CreatorCard";
import "./CreatorPage.css";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../utils/firebase-config";
import ProductCard from "../../component/ProductCard/ProductCard";
import ProductActivityCard from "../../component/ProductActivityCard/ProductActivityCard";
import { Icon } from "@iconify/react";

const fetchCreatorByID = async (creatorId) => {
  const creatorDocRef = doc(db, "creators", creatorId);
  const creatorSnapshot = await getDoc(creatorDocRef);
  let products = [];
  for (const moisturizer of creatorSnapshot.data().moisturizers) {
    const productDocRef = doc(db, "products", moisturizer);
    const productSnapshot = await getDoc(productDocRef);
    products.push(productSnapshot.data());
  }
  let posts = [];
  for (const post of creatorSnapshot.data().posts) {
    const postDocRef = doc(db, "posts", post);
    const postSnapshot = await getDoc(postDocRef);
    posts.push(postSnapshot.data());
  }

  return {
    ...creatorSnapshot.data(),
    products: products,
    postsList: posts,
  };
};
function CreatorPage() {
  const { id } = useParams();
  const {
    data: creator,
    isLoading,
    isError,
  } = useQuery(["creator", id], () => fetchCreatorByID(id));

  return (
    <div className="main-content">
      <Container>
        <CreatorCard data={creator} />
      </Container>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="produts-tabs-wrap">
          <Nav variant="pills" className="produts-tabs">
            <Nav.Item>
              <Nav.Link eventKey="first">Moisturizers</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Activity</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Container>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              {/* <div className="Moisturizers-Title">
                <h6>recommended</h6>
                <div className="moisturizers-sort">
                  <span>sort by :</span>
                  <select>
                    <option>dotScore</option>
                    <option>trending</option>
                  </select>
                </div>
              </div> */}
              <div className="search-box">
                <input
                  type="text"
                  placeholder="search your next go-to"
                />
                <Link to="#">
                  <Icon icon="prime:search" />
                </Link>
              </div>
              <div className="ProductCardList">
                {creator?.products?.map((item, index) => (
                  <ProductCard data={item} key={index} />
                ))}
              </div>
              {/* <Moisturizers MoisturizersTitle="recommended" data/> */}
              <Button variant="" className="btn btn-black btn-skin" >
                sort by: trending
              </Button>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div className="search-box">
                <input
                  type="text"
                  placeholder="search moisturizers, creators & more "
                />
                <Link to="#">
                  <Icon icon="prime:search" />
                </Link>
              </div>
              <div className="ProductActivityCardList">
                {creator?.postsList?.map((item, index) => (
                  <ProductActivityCard data={item} key={index} />
                ))}
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Container>
      </Tab.Container>
    </div>
  );
}

export default CreatorPage;
