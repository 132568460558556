import React, { useEffect, useState, Suspense, lazy } from 'react';
import './BundleDetail.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Nav, Tab } from 'react-bootstrap';
import ProductActivity from '../../component/ProductActivity/ProductActivity';
import { db } from '../../utils/firebase-config';
import { doc, getDoc } from 'firebase/firestore';

const MobileImageBundleDetail = lazy(() => import('../../component/MobileImageBundleDetail/MobileImageBundleDetail'));
const DesktopImageBundleDetail = lazy(() => import('../../component/DesktopImageBundleDetail/DesktopImageBundleDetail'));
const MobileBundleDetail = lazy(() => import('../../component/MobileBundleDetail/MobileBundleDetail'));
const DesktopBundleDetail = lazy(() => import('../../component/DesktopBundleDetail/DesktopBundleDetail'));

const fetchBundleById = async (bundleId) => {
  const bundleDocRef = doc(db, "bundles", bundleId);
  const bundleDoc = await getDoc(bundleDocRef);

  if (!bundleDoc.exists()) {
    throw new Error("Bundle not found!");
  }

  return bundleDoc.data();
};

const fetchProductById = async (productId) => {
  const productDocRef = doc(db, "products", productId);
  const productDoc = await getDoc(productDocRef);

  if (!productDoc.exists()) {
    throw new Error("Product not found!");
  }

  return productDoc.data();
};

const BundleDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [bundle, setBundle] = useState(null);
  const [products, setProducts] = useState(null); // Initialize products to null
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bundleData = await fetchBundleById(id);
        setBundle(bundleData);

        const faceWash = await fetchProductById(bundleData?.face_wash?.id);
        const moisturizer = await fetchProductById(bundleData?.moisturizer?.id);
        const sunscreen = await fetchProductById(bundleData?.sunscreen?.id);
        // const mystery = await fetchProductById(bundleData?.mystery?.id);


        setProducts({
          face_wash: faceWash,
          moisturizer: moisturizer,
          sunscreen: sunscreen,
          // mystery: mystery,
        });

        setLoading(false); // Set loading to false once data is loaded
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Ensure loading is set to false even if there's an error
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="bundle-detail">
      <Suspense fallback={<div className="loading"></div>}>
        <div className="bundle-image">
          {isMobile ? (
            <MobileImageBundleDetail bundle={bundle} products={products}/>
          ) : (
            <DesktopImageBundleDetail bundle={bundle} />
          )}
        </div>
        <div className="bundle-detail-wrap">
          <Tab.Container id="left-tabs-example" defaultActiveKey="details">
            <div className="produts-tabs-wrap" style={{padding: "16px 24px 2px", marginBottom: "0px"}}>
              <Nav variant="pills" className="bundle-tabs">
                <Nav.Item>
                  <Nav.Link eventKey="details">Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="explore">Explore</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Container>
              <Tab.Content>
                <Tab.Pane eventKey="details">
                  <Suspense fallback={<div className="loading"></div>}>
                    {loading ? (
                      <div className="loading"></div>
                    ) : isMobile ? (
                      <MobileBundleDetail bundle={bundle} products={products} />
                    ) : (
                      <DesktopBundleDetail bundle={bundle} products={products} />
                    )}
                  </Suspense>
                </Tab.Pane>
                <Tab.Pane eventKey="explore">
                  <ProductActivity productId={id} />
                </Tab.Pane>
              </Tab.Content>
            </Container>
          </Tab.Container>
        </div>
      </Suspense>
    </div>
  );
};

export default BundleDetail;
